/** @format */

import React from 'react';
import type { LegacyCitationProp } from './faq-list';

/**
 * Citations.
 *
 * @param {string} citationId
 *   FAQ question id.
 * @param {Array} citationList
 *   List of all current language questions.
 */
const Citations: React.FC<{
  citationId: string;
  citationList: LegacyCitationProp[];
}> = ({ citationId, citationList }) => {
  const citationItems = citationList.map((item, index) => {
    const { cite, citation_category } = item.citation;

    if (citationId === 'all' || citationId === citation_category) {
      return (
        <li
          key={`citation-${index}`}
          id={`citation-${index}`}
          className="citation"
        >
          <cite>{cite}</cite>
        </li>
      );
    }
    return null;
  });
  return <>{citationItems}</>;
};
export default Citations;
