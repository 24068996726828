import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import type { PageDataProps } from '@context';

/**
 * Get Image Data.
 */
export const getImageData = (
  imageType: string,
  pageData?: PageDataProps
): null | IGatsbyImageData | undefined => {
  if (pageData?.currentPage?.frontmatter) {
    // @ts-ignore
    const bannerImage = pageData?.currentPage?.frontmatter?.[imageType];
    if (bannerImage) {
      const { gatsbyImageData } = bannerImage?.childImageSharp;
      return gatsbyImageData ? getImage(gatsbyImageData) : null;
    }
  }
  return null;
};
