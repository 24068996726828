import React, { ReactNode } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '@utilities';
import { usePageData } from '@context';
import type { PageContextProps } from '@context';

/**
 * Content Banner.
 */
const ContentBanner: React.FC<{
  backgroundColor: string;
  textOrientationLeft?: boolean;
  bannerAltTag?: string;
  largerTextArea?: boolean;
  storybookImage?: string;
  children: ReactNode;
}> = ({
  backgroundColor,
  textOrientationLeft = false,
  bannerAltTag,
  storybookImage,
  children,
}) => {
  const { pageData }: PageContextProps = usePageData();
  if (!pageData) return null;
  const safeImage = getImageData('content_banner_image', pageData);

  /**
   * Render.
   */
  if (safeImage) {
    return (
      <section
        className={`banner-wrapper background-${backgroundColor} content-banner banner-text-${
          textOrientationLeft ? 'left' : 'right'
        }`}
      >
        <div className="blade-wrapper">
          <div className="blade-container">
            <div className="text-container center-vertically">{children}</div>
          </div>
        </div>
        <div className="img-container">
          {storybookImage && storybookImage !== '' ? (
            <div className="gatsby-image-wrapper">
              <img
                src={storybookImage}
                alt={bannerAltTag ?? ''}
                className="storybook-img"
              />
            </div>
          ) : (
            <GatsbyImage image={safeImage} alt={bannerAltTag ?? ''} />
          )}
        </div>
      </section>
    );
  }
  return null;
};
export default ContentBanner;
