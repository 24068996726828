/** @format */

import { useState, useLayoutEffect } from 'react';

/**
 * Window Size Props.
 */
interface WindowSizeProps {
  width: number;
  height: number;
}

/**
 * Use Window Size.
 *
 * @returns
 */
export const useWindowSize = (): WindowSizeProps => {
  const [windowSize, setWindowSize] = useState<WindowSizeProps>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    const updateSize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 200);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return windowSize;
};
