/** @format */

import React from 'react';
import { Link } from 'gatsby';

/**
 * Japan Top Menu.
 */
const JaMenu = (): JSX.Element => {
  /**
   * Render.
   */
  return (
    <nav className="top-navigation">
      <ul>
        <li className="top-navigation-item">
          <Link
            to={`/ja/cancer-types/cancer-types/`}
          >{`治療と生活について`}</Link>
          <div className="drop-down-container">
            <ul className={`ja-submenu`}>
              <li>
                <Link to={`/ja/cancer-types/radiation-therapy/flow`}>
                  放射線治療の流れ
                </Link>
              </li>
              <li>
                <Link to={`/ja/cancer-types/radiation-therapy/side-effects`}>
                  放射線治療の副作用
                </Link>
              </li>
              <li>
                <Link to={`/ja/cancer-types/radiation-therapy/faqs`}>
                  放射線治療の Q&A
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="top-navigation-item">
          <Link
            to={`/ja/treatments/radiation-therapy/`}
          >{`放射線治療について`}</Link>
          <div className="drop-down-container">
            <ul className={`ja-submenu`}>
              <li>
                <Link to={`/ja/treatments/radiation-therapy/about`}>
                  放射線治療とは
                </Link>
              </li>
              <li>
                <Link to={`/ja/treatments/radiation-therapy/feature`}>
                  放射線治療の特徴
                </Link>
              </li>
              <li>
                <Link to={`/ja/treatments/radiation-therapy/types`}>
                  放射線治療の種類
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  );
};
export default JaMenu;
