/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { useSiteData, useStateContext } from '@context';
import type { DataContextProps, StateContextProps } from '@context';

/**
 * Language links.
 *
 * Collection of locale links used in the language dropdown menu.
 */
const LanguageLinks = (): JSX.Element | null => {
  const { siteData, setSiteData }: DataContextProps = useSiteData();
  const { setResponsiveMenuOpen }: StateContextProps = useStateContext();
  if (siteData && setSiteData) {
    const { translations } = siteData;

    /**
     * Update Language.
     */
    const updateLanguage = (locale: string): void => {
      setSiteData((siteData) => ({ ...siteData, currentLocale: locale }));
      setResponsiveMenuOpen(false);
    };

    /**
     * Render.
     */
    if (translations) {
      return (
        <ul>
          <li>
            <Link to={`/`} onClick={() => updateLanguage('en')}>
              {translations['english']} (English)
            </Link>
          </li>
          <li>
            <Link to={`/ja`} onClick={() => updateLanguage('ja')}>
              {translations['japanese']} (日本語)
            </Link>
          </li>
          <li>
            <Link to={`/pt-br`} onClick={() => updateLanguage('pt-br')}>
              {translations['portuguese']} (Português)
            </Link>
          </li>
          {/* 
            <li>
              <Link to={`/es-xl`} onClick={() => updateLanguage('es-xl')}>
                {translations['spanish']} (Español)
              </Link>
            </li> 
          */}
        </ul>
      );
    }
  }
  return null;
};
export default LanguageLinks;
