import React, { ReactNode } from 'react';
import Ripple from './ripple';

export interface ButtonProps {
  buttonType?: 'button' | 'submit' | 'reset' | undefined;
  primary?: boolean;
  disabled?: boolean;
  large?: boolean;
  children: ReactNode;
  onButtonClick?: (event: any) => void;
}

/**
 * Button base component. Leverages Material UI design styling.
 */
const Button: React.FC<ButtonProps> = ({
  buttonType = 'button',
  primary = true,
  large = false,
  disabled = false,
  children,
  onButtonClick,
}) => {
  return (
    <button
      type={buttonType}
      onClick={onButtonClick}
      disabled={disabled}
      className={`${primary ? 'primary' : 'secondary'} ${large ? 'large' : ''}`}
    >
      {children}
      {!disabled && <Ripple />}
    </button>
  );
};
export default Button;
