/** @format */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSiteData, DataContextProps } from '@context';
import GlossaryList from './glossary-list';

type CurrentGlossaryProps = {
  language: string;
  glossary_terms: TermProps[];
};

type GlossaryItemProps = {
  node: {
    frontmatter: CurrentGlossaryProps;
    id: string;
  };
};

type GlossaryDataProps = {
  data: GlossaryItemProps[];
};

type TermProps = {
  term: {
    term_definition: string;
    term_name: string;
    term_group: string;
  };
  language: string;
};

type DataProps = {
  pages: {
    edges: GlossaryDataProps[];
  };
};

/**
 * Get Current Glossary Language.
 */
const getCurrentGlosarryLanguage = (
  data: GlossaryDataProps[],
  language: string
): CurrentGlossaryProps | null => {
  let currentGlossary = null;
  // @ts-ignore
  data.forEach((glossary: GlossaryItemProps) => {
    const { language: glossaryLanguage } = glossary.node.frontmatter;
    if (glossaryLanguage && glossaryLanguage === language) {
      currentGlossary = glossary.node.frontmatter;
    }
  });
  return currentGlossary;
};

/**
 * Custom sort for glossary terms.
 */
const glossarySort = (a: TermProps, b: TermProps): number => {
  const termA = a.term.term_name;
  const termB = b.term.term_name;

  if (termA && termB) {
    if (termA.toLowerCase() < termB.toLowerCase()) {
      return -1;
    }
    if (termA.toLowerCase() > termB.toLowerCase()) {
      return 1;
    }
  }
  return 0;
};

/**
 * Custom sort for Japan glossary terms.
 */
const glossarySortJapan = (a: TermProps, b: TermProps): number => {
  const termA = a.term.term_group;
  const termB = b.term.term_group;

  if (termA && termB) {
    return termA.localeCompare(termB, 'ja');
  }
  return 0;
};

/**
 * Glossary.
 */
const Glossary: React.FC<{ showGlossary: boolean | null }> = ({
  showGlossary,
}) => {
  const { siteData }: DataContextProps = useSiteData();
  const data: DataProps = useStaticQuery(graphql`
    {
      pages: allMarkdownRemark(
        filter: { frontmatter: { glossary_dictionary: { eq: true } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              language
              glossary_terms {
                term {
                  term_name
                  term_group
                  term_definition
                }
              }
            }
          }
        }
      }
    }
  `);

  if (siteData) {
    const { currentLocale } = siteData;
    const language = currentLocale ?? 'en';

    if (showGlossary) {
      const { edges } = data.pages;
      const currentGlossary = getCurrentGlosarryLanguage(edges, language);
      const glossaryTerms = currentGlossary?.glossary_terms ?? null;
      if (glossaryTerms) {
        if (language === 'ja') {
          glossaryTerms.sort(glossarySortJapan);
        } else {
          glossaryTerms.sort(glossarySort);
        }
        return (
          <>
            {language === 'ja' && (
              <p>
                ※本用語集は、米国国立がん研究所&#40;NATIONAL CANCER
                INSTITUTE&#58;NCI&#41;を情報
                の出展元とし、翻訳したものを掲載しております。
              </p>
            )}
            <div className="glossary-anchor-links-container">
              <GlossaryList
                terms={glossaryTerms}
                glossaryLink={true}
                language={language}
              />
            </div>
            <dl className="glossary-container">
              <GlossaryList terms={glossaryTerms} language={language} />
            </dl>
          </>
        );
      }
    }
  }
  return <></>;
};
export default Glossary;
