import React, { useMemo, useState } from 'react';
import { FaqsFrontmatter } from '@interfaces';
import { Button, SelectList } from '@base-components';
import { useWindowSize } from '@hooks';

interface FaqMenuGroup {
  groupTitle: string;
  menuItems: FaqMenuItem[];
}

interface FaqMenuItem {
  menuItemText?: string | null;
  menuItemValue: string;
}

/**
 * Get FAQ Menu Items.
 */
const getFaqMenuItems = (
  faqList: FaqsFrontmatter,
  faqGroups: string[]
): FaqMenuGroup[] => {
  let menuItems = [];
  const { questions } = faqList;
  if (faqGroups?.length > 0 && questions && questions?.length > 0) {
    for (const primaryGroup of faqGroups) {
      const menuGroup: FaqMenuGroup = {
        groupTitle: primaryGroup,
        menuItems: [],
      };

      for (const secondaryGroup of questions) {
        if (!secondaryGroup.faq_group) continue;

        const {
          faq_parent_category: primaryCategory,
          faq_category: category,
          faq_header: header,
        } = secondaryGroup.faq_group;
        if (primaryCategory === primaryGroup) {
          menuGroup?.menuItems.push({
            menuItemText: header,
            menuItemValue: category,
          });
        }
      }
      menuItems.push(menuGroup);
    }
  }
  return menuItems;
};

/**
 * FaqsMenu.
 */
const FaqsMenu: React.FC<{
  faqPrimaryGroups: string[];
  faqList: FaqsFrontmatter;
  setActiveGroup: React.Dispatch<React.SetStateAction<string>>;
  activeGroup: string;
}> = ({ faqPrimaryGroups, faqList, setActiveGroup, activeGroup }) => {
  const [navigationItems, setNavigationItems] = useState<FaqMenuGroup[]>([]);
  const { width } = useWindowSize();

  /**
   * Build FAQ Navigation.
   */
  useMemo(() => {
    const menuItems = getFaqMenuItems(faqList, faqPrimaryGroups);
    setNavigationItems(menuItems);
  }, [faqList, faqPrimaryGroups]);

  /**
   * Render.
   */
  if (navigationItems?.length > 0) {
    if (width < 767) {
      return (
        <SelectList
          groupedListItems={navigationItems}
          groupedListType={true}
          label="All FAQs"
          onClickEvent={setActiveGroup}
        />
      );
    }
    return (
      <nav className="faq-side-navigation">
        <ul>
          {navigationItems.map((menu, index) => {
            const { groupTitle, menuItems } = menu;
            return (
              <li key={index}>
                {groupTitle}
                <ul>
                  {menuItems?.length > 0 &&
                    menuItems.map((menuItem) => {
                      const { menuItemText, menuItemValue } = menuItem;
                      return (
                        <li
                          onClick={() => setActiveGroup(menuItemValue)}
                          className={
                            activeGroup === menuItemValue ? 'active' : ''
                          }
                          key={menuItemValue}
                        >
                          {menuItemText}
                        </li>
                      );
                    })}
                </ul>
              </li>
            );
          })}
        </ul>
        {activeGroup !== 'all' && (
          <Button
            buttonType="button"
            onButtonClick={() => setActiveGroup('all')}
          >
            Show all FAQs
          </Button>
        )}
      </nav>
    );
  }
  return <>Faqs menu goes here</>;
};
export default FaqsMenu;
