/** @format */

import React from 'react';
import { Link } from '@base-components';
import { useStateContext } from '@context';
import type { StateContextProps } from '@context';

/**
 * Japan Side Navigation.
 */
const JaSideNavigation: React.FC<{ responsiveMenu?: boolean }> = ({
  responsiveMenu = false,
}): JSX.Element => {
  const { setResponsiveMenuOpen }: StateContextProps = useStateContext();

  /**
   * Close responsive menu and reset dropdown menus.
   */
  const resetResponsiveMenu = (): void => {
    if (responsiveMenu) {
      setResponsiveMenuOpen(false);
    }
  };

  /**
   * Render.
   */
  return (
    <nav>
      <ul className={`side-navigation`}>
        <li className="navigation-header">
          <Link
            url={`/ja/cancer-types/cancer-types/`}
            onLinkClick={() => resetResponsiveMenu()}
          >{`治療と生活について`}</Link>
          <ul className={`ja-submenu`}>
            <li>
              <Link
                url={`/ja/cancer-types/radiation-therapy/flow`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療の流れ
              </Link>
            </li>
            <li>
              <Link
                url={`/ja/cancer-types/radiation-therapy/side-effects`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療の副作用
              </Link>
            </li>
            <li>
              <Link
                url={`/ja/cancer-types/radiation-therapy/faqs`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療の Q&A
              </Link>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="side-navigation">
        <li className="navigation-header">
          <Link
            url={`/ja/treatments/radiation-therapy/`}
            onLinkClick={() => resetResponsiveMenu()}
          >{`放射線治療について`}</Link>
          <ul className={`ja-submenu`}>
            <li>
              <Link
                url={`/ja/treatments/radiation-therapy/about`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療とは
              </Link>
            </li>
            <li>
              <Link
                url={`/ja/treatments/radiation-therapy/feature`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療の特徴
              </Link>
            </li>
            <li>
              <Link
                url={`/ja/treatments/radiation-therapy/types`}
                onLinkClick={() => resetResponsiveMenu()}
              >
                放射線治療の種類
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};
export default JaSideNavigation;
