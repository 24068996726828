import React from 'react';
import * as Select from '@radix-ui/react-select';
import SelectItem from './select-item';

interface MenuItemProps {
  menuItemText: string;
  menuItemValue: string;
}

interface MenuGroupProps {
  groupTitle: string;
  menuItems: MenuItemProps[];
}

/**
 * Select List.
 */
const SelectList: React.FC<{
  listItems?: string[];
  onClickEvent: (value: string) => void;
  label: string;
  groupedListItems?: any;
  groupedListType?: boolean;
}> = ({
  listItems,
  onClickEvent,
  label,
  groupedListItems,
  groupedListType = false,
}) => {
  /**
   * Render.
   */
  if (groupedListType && groupedListItems && groupedListItems.length > 0) {
    /**
     * Grouped select list.
     */
    return (
      <div className="select-wrapper">
        <Select.Root onValueChange={(value) => onClickEvent(value)}>
          {/* @ts-ignore */}
          <Select.Trigger className="select-trigger" placeholder={label}>
            <Select.Value placeholder={label} />
          </Select.Trigger>
          <Select.Content className="select-content">
            <Select.ScrollUpButton className="select-scroll-button" />
            <Select.Viewport className="select-viewport">
              {groupedListItems.map((group: MenuGroupProps, index: number) => {
                const { groupTitle, menuItems } = group;
                return (
                  <Select.Group key={index}>
                    <Select.Label className="select-label">
                      {groupTitle}
                    </Select.Label>
                    {menuItems.map((item: MenuItemProps) => {
                      const { menuItemText, menuItemValue } = item;
                      return (
                        // @ts-ignore
                        <SelectItem value={menuItemValue} key={menuItemValue}>
                          {menuItemText}
                        </SelectItem>
                      );
                    })}
                  </Select.Group>
                );
              })}
            </Select.Viewport>
          </Select.Content>
        </Select.Root>
      </div>
    );
  } else if (listItems && listItems.length > 0 && onClickEvent) {
    /**
     * Basic select list.
     */
    return (
      <div className="select-wrapper">
        <Select.Root onValueChange={(value) => onClickEvent(value)}>
          {/* @ts-ignore */}
          <Select.Trigger className="select-trigger" placeholder={label}>
            <Select.Value placeholder={label} />
          </Select.Trigger>
          <Select.Content className="select-content">
            <Select.ScrollUpButton className="select-scroll-button" />
            <Select.Viewport className="select-viewport">
              <Select.Group>
                <Select.Label className="select-label">{label}</Select.Label>
                {listItems.map((item) => {
                  const safeName = item.toLowerCase().replace(/\s/g, '-');
                  return (
                    // @ts-ignore
                    <SelectItem value={safeName} key={safeName}>
                      {item}
                    </SelectItem>
                  );
                })}
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Root>
      </div>
    );
  }
  return null;
};
export default SelectList;
