/** @format */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useSiteData, DataContextProps } from '@context';
import Citations from './citations';
import Faq from './faq';

type LegacyFaqListProps = {
  pages: {
    edges: {
      node: {
        id: string;
        frontmatter: {
          language: string;
          citations: LegacyCitationProp[];
          questions: LegacyFaqProp[];
        };
      };
    }[];
  };
};

export type LegacyFaqProp = {
  faq: {
    faq_key: string;
    faq_category: string;
    question: string;
    answer: string;
  };
};

export type LegacyCitationProp = {
  citation: {
    cite: string;
    citation_category: string;
  };
};

/**
 * Faqs.
 */
const FaqList: React.FC<{ questionsList: string[] }> = ({ questionsList }) => {
  const { siteData }: DataContextProps = useSiteData();
  const data: LegacyFaqListProps = useStaticQuery(graphql`
    {
      pages: allMarkdownRemark(
        filter: { frontmatter: { question_list: { eq: true } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              language
              citations {
                citation {
                  cite
                  citation_category
                }
              }
              questions {
                faq {
                  faq_key
                  faq_category
                  question
                  answer
                }
              }
            }
          }
        }
      }
    }
  `);
  if (siteData) {
    const { currentLocale } = siteData;
    const language = !currentLocale ? 'en' : currentLocale;

    if (questionsList) {
      const allFaqs = data.pages.edges;
      let faqs;
      let faqLinks;
      let allCitations;

      allFaqs.forEach((faqList) => {
        const {
          language: faqLanguage,
          questions,
          citations,
        } = faqList.node.frontmatter;

        if (faqLanguage === language) {
          if (citations) {
            allCitations = questionsList.map((typeOfTherapy) => {
              return (
                <Citations
                  citationId={typeOfTherapy}
                  citationList={citations}
                  key={typeOfTherapy}
                />
              );
            });
          }

          faqs = questionsList.map((question) => {
            return (
              <Faq questionId={question} faqList={questions} key={question} />
            );
          });

          faqLinks = questionsList.map((question) => {
            return (
              <Faq
                questionId={question}
                faqList={questions}
                key={question}
                faqLink={true}
              />
            );
          });
        }
      });

      if (faqs) {
        return (
          <>
            <ul>{faqLinks}</ul>
            {faqs}
            <br />
            <ol>{allCitations}</ol>
          </>
        );
      }
    }
  }
  return null;
};
export default FaqList;
