import React from 'react';
import { usePageData, useSiteData } from '@context';
import type { PageContextProps, DataContextProps } from '@context';
import { Link } from '@base-components';

/**
 * Breadcrumb.
 */
const Breadcrumb = (): JSX.Element | null => {
  const { pageData }: PageContextProps = usePageData();
  const { siteData }: DataContextProps = useSiteData();

  if (pageData?.currentPage?.frontmatter && siteData) {
    const { translations } = siteData;
    const { path, title, id } = pageData?.currentPage?.frontmatter;
    const pathArray = path ? path.split('/') : [];

    if (pathArray && pathArray.length > 2 && translations) {
      const pathLangcode = pathArray[1];
      const crumbs = pathArray.slice(2);
      let crumbPath = '';

      /**
       * Render.
       */
      return (
        <nav aria-label="breadcrumbs" className="breadcrumbs">
          <ul>
            {crumbs.map((crumb: string) => {
              crumbPath = `${crumbPath}/${crumb}`;
              const translationKey = crumb.toLowerCase().replace('-', '_');
              const linkText =
                id === crumb ? title : translations[translationKey];
              return (
                <li key={crumb}>
                  <Link url={`/${pathLangcode}${crumbPath}`}>{linkText}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      );
    }
  }
  return null;
};
export default Breadcrumb;
