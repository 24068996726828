/**
 * Patient Site Context.
 *
 * @format
 */
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

export type StateContextProps = {
  responsiveMenuOpen: boolean;
  setResponsiveMenuOpen: Dispatch<SetStateAction<boolean>>;
};

/**
 * Initial patient state. context.
 */
export const StateContext = createContext<StateContextProps>({
  responsiveMenuOpen: false,
  setResponsiveMenuOpen: () => false,
});

export const useStateContext = () => useContext(StateContext);

/**
 * Translation Provider.
 */
export const StateProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [responsiveMenuOpen, setResponsiveMenuOpen] = useState<boolean>(false);

  /**
   * Render.
   */
  return (
    <StateContext.Provider
      value={{ responsiveMenuOpen, setResponsiveMenuOpen }}
    >
      {children}
    </StateContext.Provider>
  );
};
