/** @format */

import React, { useEffect, useState } from 'react';
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import { useDebounce } from '@hooks';

/**
 * Custom Search Box.
 */
const SearchBox: React.FC<UseSearchBoxProps> = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const initialQuery = urlParams.get('q') ?? '';
  const { refine } = useSearchBox(props);
  const [searchTerm, setSearchTerm] = useState(initialQuery);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  /**
   * Update Search.
   *
   * @param event
   */
  const updateSearch = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (
      (event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>)
        .key === 'Enter'
    ) {
      event.preventDefault();
    }
    const { host, protocol, pathname } = window.location;
    const searchUrl = `${protocol}//${host}${pathname}?q=${
      (event.target as HTMLInputElement | HTMLTextAreaElement).value
    }`;
    window.history.pushState({ path: searchUrl }, '', searchUrl);
    setSearchTerm(
      (event.target as HTMLInputElement | HTMLTextAreaElement).value
    );
  };

  /**
   * Debounce Search.
   */
  useEffect(() => {
    if (debouncedSearchTerm) {
      refine(searchTerm);
    } else {
      refine('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  /**
   * Render.
   */
  return (
    <form className="search-box-form" noValidate role="search">
      <input
        type="search"
        className={`search ${
          searchTerm && searchTerm !== '' ? 'not-empty' : ''
        }`}
        autoFocus
        value={searchTerm}
        id="search-box"
        name="search"
        onChange={(event) => updateSearch(event)}
        onKeyDown={(event) => updateSearch(event)}
      />
      <label htmlFor="search">Search</label>
      <i className="fas fa-search" />
    </form>
  );
};
export default SearchBox;
