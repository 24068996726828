/** @format */

import React, { ReactNode } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useSiteData, DataContextProps } from '@context';

/**
 * No Results Boundary.
 */
const NoResultsBoundary: React.FC<{ children: ReactNode[] }> = ({
  children,
}) => {
  const { siteData }: DataContextProps = useSiteData();
  const { results, indexUiState } = useInstantSearch();
  if (!siteData) return null;

  const { translations } = siteData;
  const { __isArtificial: artificial, nbHits, page, hitsPerPage } = results;
  const endResultCount =
    (page + 1) * hitsPerPage > nbHits
      ? nbHits - page * hitsPerPage
      : (page + 1) * hitsPerPage;

  if (!indexUiState.query) {
    return (
      <div className="search-header">
        <p>{translations?.start_typing}</p>
      </div>
    );
  }

  if ((!artificial && nbHits === 0) || indexUiState.query?.length < 3) {
    return (
      <div className="search-header">
        <p>
          {translations?.no_results}{' '}
          <q>
            <b>{indexUiState.query}</b>
          </q>
          .
        </p>
      </div>
    );
  }

  /**
   * Render.
   */
  return (
    <>
      <div className="search-header">
        <p>
          {translations?.search_results}{' '}
          <q>
            <b>{indexUiState.query}</b>
          </q>
        </p>
        <p className="result-count">
          {translations?.showing} {page * hitsPerPage + 1} - {endResultCount}{' '}
          {translations?.of} {nbHits} {translations?.results}
        </p>
      </div>
      {children}
    </>
  );
};
export default NoResultsBoundary;
