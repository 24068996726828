export const shareStoryFields = [
  {
    label: 'First Name',
    type: 'text',
    name: 'first',
    required: true,
    valueMissing: 'First name required.',
  },
  {
    label: 'Last Name',
    type: 'text',
    name: 'last',
    required: true,
    valueMissing: 'Last name required.',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
    required: true,
    valueMissing: 'Email is required.',
    typeMismatch: 'Invalid email',
  },
  {
    label: 'Story',
    type: 'textarea',
    name: 'story',
    required: true,
    valueMissing: 'Story information required.',
  },
];
