// @ts-nocheck
import type { NavigationData, AllPageData } from '@interfaces';

/**
 * Build Navigation Data.
 *
 * @param {Object} data
 *   All frontmatter data.
 * @param {string} lang
 *   Language key.
 * @param {string} pageType
 *   Page template type.
 */
export const buildNavigationData = (
  data: AllPageData,
  lang: string,
  pageType: string
) => {
  const edges = pageType === 'legacy' ? data?.edges : data?.nodes;
  let navigationData: NavigationData = {};

  if (edges) {
    edges.forEach((edge) => {
      const navigationItem =
        pageType === 'legacy' ? edge?.node?.frontmatter : edge.frontmatter;

      if (!navigationItem) return;

      const {
        title,
        language,
        content_type: contentType,
        node_type: nodeType,
        show_in_navigation: show,
        id,
      } = navigationItem;

      if (nodeType && title && show !== false && lang === language) {
        /**
         * Top level navigation.
         */
        if (!navigationData[nodeType]) {
          navigationData[nodeType] = { menu: nodeType, menuItems: {} };
        }

        /**
         * empowerME submenus.
         */
        if (pageType === 'empower-me' && nodeType !== 'about' && !contentType) {
          if (!navigationData[nodeType].menuItems[id]) {
            navigationData[nodeType].menuItems[id] = navigationItem;
          }
        }

        /**
         * Legacy submenus.
         */
        if (pageType === 'legacy') {
          if (!navigationData[nodeType].menuItems[contentType]) {
            const contentTypeMenu = {
              menu: contentType,
              path: `/${language}/${nodeType}/${contentType}/`,
              menuItems: {},
              language: language,
              node: nodeType,
            };
            navigationData[nodeType].menuItems[contentType] = contentTypeMenu;
          }

          const safeTitle = title
            .toLowerCase()
            .replace(new RegExp(' ', 'g'), '-');
          navigationData[nodeType].menuItems[contentType].menuItems[safeTitle] =
            navigationItem;
        }
      }
    });
  }
  return navigationData;
};

/**
 * Get top level navigation order.
 *
 * @param {string} pageType Current page type.
 * @return {Array} Top level navigation items.
 */
export const getNavigationOrder = (pageType: string): string[] => {
  if (pageType === 'legacy') {
    return ['cancer-types', 'patient-stories', 'treatments'];
  }
  return ['about', 'cancer-types', 'treatments', 'resources'];
};
