/** @format */

import React, { useState } from 'react';
import { useSiteData, DataContextProps } from '@context';
import { Link } from 'gatsby';
import { mapObject } from '@utilities';
import { MenuData } from '@interfaces';

/**
 * Side Navigation Submenu
 */
const SideNavigationSubmenu: React.FC<{
  categoryMenuData: MenuData[];
  menuId: string;
  pageId: string;
}> = ({ categoryMenuData, menuId, pageId }) => {
  const [toggleSubMenu, setToggleSubmenu] = useState<Boolean>(false);
  const { siteData }: DataContextProps = useSiteData();

  if (siteData) {
    const { translations } = siteData;
    // @ts-ignore
    const { path, menu, menuItems } = categoryMenuData[menuId];
    const safeKey = menu.replace(new RegExp('-', 'g'), '_');
    let index = 0;

    /**
     * Render.
     */
    if (translations) {
      return (
        <li className={toggleSubMenu ? 'show-menu' : ''}>
          <Link to={path}>{translations[safeKey]}</Link>
          <span onClick={() => setToggleSubmenu(!toggleSubMenu)}>
            <i
              className={
                toggleSubMenu
                  ? `fas fa-angle-down show-menu`
                  : `fas fa-angle-down`
              }
            />
          </span>
          <ul className={`submenu`}>
            {menuItems &&
              mapObject(menuItems, (key: number) => {
                const { path, title, id } = menuItems[key];
                return (
                  <li className={pageId === id ? 'active' : ''} key={index++}>
                    <Link to={path}>{title}</Link>
                  </li>
                );
              })}
          </ul>
        </li>
      );
    }
  }
  return null;
};
export default SideNavigationSubmenu;
