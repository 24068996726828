/** @format */

import React, { useState, useEffect, CSSProperties } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getBannerSubtitle } from '@utilities';
import { useSiteData, DataContextProps } from '@context';

type BannerProps = {
  title: string;
  titleColor?: string;
  subtitleColor?: string;
  gradientDirection?: number;
  gradientColorA?: string;
  gradientColorB?: string;
  bannerImage: any;
  nodeType?: string;
  contentType?: string;
};

/**
 * Banner.
 */
const Banner: React.FC<BannerProps> = ({
  title,
  titleColor,
  subtitleColor,
  gradientDirection,
  gradientColorA,
  gradientColorB,
  bannerImage,
  nodeType,
  contentType,
}) => {
  const [loaded, setLoaded] = useState<Boolean>(false);
  const { siteData }: DataContextProps = useSiteData();

  /**
   * Fade in banner.
   */
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 225);
  }, []);

  if (!siteData) return null;

  const subtitle =
    nodeType && contentType
      ? getBannerSubtitle(nodeType, contentType, title, siteData)
      : '';
  const safeImage = getImage(bannerImage);

  /**
   * Render.
   */
  if (safeImage) {
    return (
      <div
        className="legacy-banner-wrapper"
        style={
          {
            '--gradient-direction': `${gradientDirection}deg`,
            '--gradient-color-a': gradientColorA,
            '--gradient-color-b': gradientColorB,
          } as CSSProperties
        }
      >
        <div
          className={loaded ? 'banner-container' : 'loading banner-container'}
        >
          <GatsbyImage
            className={loaded ? '' : 'loading'}
            alt={subtitle === '' ? title : `${title} - ${subtitle}`}
            image={safeImage}
          />
          <div
            className={
              loaded
                ? 'center-vertically text-wrapper'
                : 'loading center-vertically text-wrapper'
            }
          >
            <div className="text-container">
              <h2 style={{ '--title-color': titleColor } as CSSProperties}>
                {title}
              </h2>
              {subtitle && (
                <h3
                  style={{ '--subtitle-color': subtitleColor } as CSSProperties}
                >
                  {subtitle}
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
export default Banner;
