export const contactFormFields = [
  {
    label: 'Name',
    type: 'text',
    name: 'name',
    required: false,
    valueMissing: 'First name required.',
  },
  {
    label: 'Email Address',
    type: 'email',
    name: 'email',
    required: false,
    typeMismatch: 'Invalid email',
  },
  {
    label: 'Phone number',
    type: 'tel',
    name: 'phone',
    required: false,
  },
  {
    label: 'Country',
    type: 'text',
    name: 'country',
    required: false,
    valueMissing: 'Country is required.',
  },
  {
    label: 'Comments',
    type: 'textarea',
    name: 'comments',
    required: true,
    valueMissing: 'Comments are required.',
  },
];
