/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { useSiteData, DataContextProps, SiteDataProps } from '@context';
import { getSafeTeaser } from '@utilities';

/**
 * Get Related Content.
 */
const getRelatedContent = (
  relatedPages: string[],
  nodeType: string,
  siteData: SiteDataProps
) => {
  if (siteData) {
    const { translations, currentLocale: language, allLegacyPages } = siteData;
    if (translations && relatedPages) {
      const relatedContent = relatedPages.map((relatedItem: string) => {
        if (!allLegacyPages?.edges) return null;

        for (const page of allLegacyPages?.edges) {
          if (!page?.node?.frontmatter) return null;

          const {
            title,
            id,
            path,
            teaser,
            language: pageLanguage,
            node_type: node,
            read_more_button: readMore,
          } = page.node.frontmatter;

          if (id === relatedItem && pageLanguage === language) {
            if (
              nodeType === node ||
              (nodeType === 'all' && node !== 'patient-stories')
            ) {
              return (
                <div className="related-content" key={id}>
                  <h3>{title}</h3>
                  <p>{teaser ? getSafeTeaser(teaser, 100) : ''}</p>
                  <div className="link-container">
                    <Link to={path} className="featured-link">
                      {readMore ? readMore : translations['learn_more']}
                    </Link>
                  </div>
                </div>
              );
            }
          }
        }
        return null;
      });
      return relatedContent.filter(Boolean);
    }
  }
  return null;
};

/**
 * Related Pages
 *
 * @param {Array} relatedPages
 */
const RelatedContent: React.FC<{
  relatedPages: string[];
}> = ({ relatedPages }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { translations } = siteData;

  /**
   * Render.
   */
  if (relatedPages && translations) {
    const oddNumberOfStories = relatedPages.length % 2 === 0 ? 'even' : 'odd';
    const pages = getRelatedContent(relatedPages, 'all', siteData);
    const stories = getRelatedContent(
      relatedPages,
      'patient-stories',
      siteData
    );

    return (
      <div
        className={`related-content-wrapper ${oddNumberOfStories} pages-${pages?.length} stories-${stories?.length}`}
      >
        {/* Related stories */}
        {stories && stories?.length > 0 && (
          <div className={`related-content-container`}>
            <h2>{translations['related_stories']}</h2>
            <div className={`related-content-items`}>{stories}</div>
          </div>
        )}

        {/* Related content */}
        {pages && pages?.length > 0 && (
          <div className={`related-content-container`}>
            <h2>{translations['related_content']}</h2>
            <div className={`related-content-items`}>{pages}</div>
          </div>
        )}
      </div>
    );
  }
};
export default RelatedContent;
