import React from 'react';

const EmpowerMeLogo = (): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="empower-me-logo"
      className="empower-me-logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 864 360"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M57.6,194.8c0-21.5,14.1-36.1,34.5-36.1c19.6,0,33.1,14.6,33.1,35.8c0,2.1-0.1,4.6-0.4,6.2H76.5
					c2,9.9,8.9,14.1,17.5,14.1c5.9,0,12.3-2.4,17.2-6.5l10.8,11.8c-7.7,7.1-17.6,10.3-29.1,10.3C72.1,230.5,57.6,216.2,57.6,194.8z
					 M106.7,188.2c-1.7-8.7-7-13.7-14.8-13.7c-8.7,0-13.7,5.2-15.3,13.7H106.7z"
              />
              <path
                className="st0"
                d="M133.7,228.7v-68.2h17.9v8c4.9-7.2,12.3-9.9,20.1-9.9c9.9,0,17.9,4.4,22.5,11.7
					c5.3-8.7,14.2-11.7,23.2-11.7c16,0,26.9,10.8,26.9,26.6v43.4h-18.3v-37.5c0-9.9-5-15.3-13.3-15.3c-7.9,0-14.4,5.1-14.4,15.4
					v37.4h-18.4v-37.5c0-9.9-4.9-15.3-13.3-15.3c-7.9,0-14.4,5.1-14.4,15.4v37.4H133.7z"
              />
              <path
                className="st0"
                d="M256.5,256.8v-96.3h17.9v7.5c4.4-5.6,11-9.4,20.3-9.4c18.9,0,33.1,15.4,33.1,35.9s-14.2,35.9-33,35.9
					c-9.1,0-15.6-3.6-20.1-8.9v35.3H256.5z M309,194.6c0-10.4-6.8-18.8-17.6-18.8c-10.4,0-17.5,8-17.5,18.8
					c0,10.8,7.1,18.8,17.5,18.8C302.2,213.4,309,205,309,194.6z"
              />
              <path
                className="st0"
                d="M332.1,194.6c0-20.5,15.6-35.9,36.8-35.9c21.3,0,36.9,15.3,36.9,35.9c0,20.5-15.7,35.9-36.9,35.9
					C347.7,230.5,332.1,215.1,332.1,194.6z M387,194.6c0-11-7.8-18.8-18.2-18.8c-10.3,0-18.1,7.7-18.1,18.8c0,11,7.7,18.8,18.1,18.8
					C379.2,213.4,387,205.6,387,194.6z"
              />
              <path
                className="st0"
                d="M427.1,228.7L404,160.5h19.6l14.6,47.6l15.3-47.6h19.4l15.5,47.9l14.6-47.9h19.2L499,228.7h-21l-15.1-46.1
					l-15,46.1H427.1z"
              />
              <path
                className="st0"
                d="M520.9,194.8c0-21.5,14.1-36.1,34.5-36.1c19.6,0,33.1,14.6,33.1,35.8c0,2.1-0.1,4.6-0.4,6.2h-48.3
					c2,9.9,8.9,14.1,17.5,14.1c5.9,0,12.3-2.4,17.2-6.5l10.8,11.8c-7.7,7.1-17.6,10.3-29.1,10.3
					C535.4,230.5,520.9,216.2,520.9,194.8z M570.1,188.2c-1.7-8.7-7-13.7-14.8-13.7c-8.7,0-13.7,5.2-15.3,13.7H570.1z"
              />
              <path
                className="st0"
                d="M597.1,228.7v-68.2h17.8v7.5c4.3-6.4,10.8-9.3,19.2-9.3c3.9,0,7,0.6,9.6,1.5l-2.7,18.2
					c-3-1.3-7.4-2.2-11.1-2.2c-8.5,0-14.4,5.2-14.4,15.3v37.2H597.1z"
              />
            </g>
          </g>
          <g>
            <path
              className="st0"
              d="M731.2,74c-41.4,0-75,33.6-75,75s33.6,75,75,75s75-33.6,75-75S772.6,74,731.2,74z M735.8,175.8h-12.5v-33.4
				l-10.7,28.4h-12.4l-10.7-28.2v33.1h-12.5v-53.4H694l12.5,32.4l12.5-32.4h16.9V175.8z M745.9,175.8v-53.4h40.2v11.6h-27.7v9h26.4
				v11.6h-26.4v9.4h28v11.6H745.9z"
            />
          </g>
        </g>
        <g>
          <path
            className="st0"
            d="M308.3,276.2l1.8-2.8c1.3,1,3.3,1.9,5.6,1.9c1.9,0,3.5-0.8,3.5-2.1c0-1.2-1.3-1.7-3.7-2.1l-1.1-0.2
			c-3.1-0.5-5.4-1.9-5.4-5c0-3.7,3.3-5.6,7-5.6c2.5,0,4.5,0.6,6.7,1.9l-1.7,2.9c-1.4-0.9-3.1-1.5-5-1.5c-2,0-3.4,0.8-3.4,2.1
			c0,1.3,1.5,1.7,3.6,2l1,0.2c3.1,0.5,5.6,1.9,5.6,4.9c0,3.9-3.4,5.7-7.2,5.7C313.1,278.5,310.2,277.7,308.3,276.2z"
          />
          <path
            className="st0"
            d="M325.9,278.2v-18.1h3.3v7.2c0.9-1.2,2.2-1.6,3.5-1.6c2.8,0,4.7,1.9,4.7,4.8v7.8h-3.3v-6.7
			c0-1.8-0.9-2.7-2.4-2.7c-1.4,0-2.6,0.9-2.6,2.8v6.7H325.9z"
          />
          <path
            className="st0"
            d="M340,272.1c0-3.7,2.5-6.4,5.9-6.4c1.7,0,2.8,0.7,3.6,1.7V266h3.2v12.2h-3.2v-1.3c-0.8,1-2,1.7-3.6,1.7
			C342.6,278.5,340,275.8,340,272.1z M349.7,272.1c0-1.9-1.3-3.4-3.1-3.4c-1.9,0-3.1,1.5-3.1,3.4s1.2,3.4,3.1,3.4
			C348.4,275.5,349.7,274,349.7,272.1z"
          />
          <path
            className="st0"
            d="M356.2,278.2V266h3.2v1.3c0.8-1.1,1.9-1.7,3.4-1.7c0.7,0,1.3,0.1,1.7,0.3l-0.5,3.3c-0.5-0.2-1.3-0.4-2-0.4
			c-1.5,0-2.6,0.9-2.6,2.7v6.6H356.2z"
          />
          <path
            className="st0"
            d="M366.1,262c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
			C367,264.1,366.1,263.2,366.1,262z M366.6,278.2V266h3.3v12.2H366.6z"
          />
          <path
            className="st0"
            d="M373.3,278.2V266h3.2v1.4c0.9-1.3,2.2-1.8,3.6-1.8c2.8,0,4.7,1.9,4.7,4.8v7.8h-3.3v-6.7
			c0-1.8-0.9-2.7-2.4-2.7c-1.4,0-2.6,0.9-2.6,2.8v6.7H373.3z"
          />
          <path
            className="st0"
            d="M388.1,282l1.2-2.7c1.2,0.8,2.6,1.2,4,1.2c2,0,3.5-1.1,3.5-3.2v-0.9c-0.8,1-2.1,1.6-3.7,1.6
			c-3,0-5.6-2.5-5.6-6.2c0-3.7,2.6-6.2,5.6-6.2c1.7,0,2.9,0.7,3.8,1.7V266h3.2v11.2c0,3.9-2.6,6.3-6.6,6.3
			C391.5,283.5,389.4,283,388.1,282z M396.9,271.9c0-1.8-1.2-3.2-3.1-3.2c-1.9,0-3.1,1.4-3.1,3.2c0,1.8,1.2,3.2,3.1,3.2
			C395.6,275.1,396.9,273.7,396.9,271.9z"
          />
          <path
            className="st0"
            d="M409.7,278.2V266h3.2v1.3c0.8-1.1,1.9-1.7,3.4-1.7c0.7,0,1.3,0.1,1.7,0.3l-0.5,3.3c-0.5-0.2-1.3-0.4-2-0.4
			c-1.5,0-2.6,0.9-2.6,2.7v6.6H409.7z"
          />
          <path
            className="st0"
            d="M419.2,272.1c0-3.8,2.5-6.5,6.2-6.5c3.5,0,5.9,2.6,5.9,6.4c0,0.4,0,0.8-0.1,1.1h-8.6c0.4,1.8,1.6,2.5,3.1,2.5
			c1.1,0,2.2-0.4,3.1-1.2l1.9,2.1c-1.4,1.3-3.1,1.8-5.2,1.8C421.8,278.5,419.2,276,419.2,272.1z M428,271c-0.3-1.6-1.2-2.4-2.7-2.4
			c-1.5,0-2.5,0.9-2.7,2.4H428z"
          />
          <path
            className="st0"
            d="M433.1,277.1l1.3-2.5c1,0.7,2.3,1.2,4.1,1.2c1.5,0,2.3-0.4,2.3-1.1c0-0.6-0.6-0.9-2-1.1l-1-0.1
			c-2.8-0.4-4.2-1.6-4.2-3.7c0-2.5,1.9-4,5.1-4c1.9,0,3.5,0.4,5.1,1.2l-1.1,2.6c-1-0.5-2.5-1-3.9-1c-1.2,0-1.9,0.4-1.9,1.1
			c0,0.7,0.9,0.9,2,1l1.1,0.2c2.6,0.4,4.1,1.6,4.1,3.6c0,2.5-2.1,4.1-5.6,4.1C436.8,278.5,434.6,278.2,433.1,277.1z"
          />
          <path
            className="st0"
            d="M445.9,272.1c0-3.7,2.8-6.4,6.6-6.4c3.8,0,6.6,2.7,6.6,6.4s-2.8,6.4-6.6,6.4
			C448.7,278.5,445.9,275.8,445.9,272.1z M455.7,272.1c0-2-1.4-3.4-3.2-3.4c-1.8,0-3.2,1.4-3.2,3.4c0,2,1.4,3.4,3.2,3.4
			C454.3,275.5,455.7,274.1,455.7,272.1z"
          />
          <path
            className="st0"
            d="M461.6,273v-7h3.3v6.7c0,1.9,1.1,2.7,2.5,2.7c1.4,0,2.5-0.8,2.5-2.7V266h3.3v7c0,3.8-2.7,5.5-5.8,5.5
			C464.3,278.5,461.6,276.8,461.6,273z"
          />
          <path
            className="st0"
            d="M476.5,278.2V266h3.2v1.3c0.8-1.1,1.9-1.7,3.4-1.7c0.7,0,1.3,0.1,1.7,0.3l-0.5,3.3c-0.5-0.2-1.3-0.4-2-0.4
			c-1.5,0-2.6,0.9-2.6,2.7v6.6H476.5z"
          />
          <path
            className="st0"
            d="M486,272.1c0-3.7,2.6-6.4,6.5-6.4c2,0,3.8,0.8,4.9,2.3l-2.4,2c-0.7-0.8-1.5-1.2-2.6-1.2
			c-1.7,0-3.1,1.3-3.1,3.4c0,2,1.3,3.4,3,3.4c1.1,0,2-0.5,2.7-1.2l2.3,2c-1.1,1.5-2.9,2.3-4.9,2.3C488.6,278.5,486,275.8,486,272.1z
			"
          />
          <path
            className="st0"
            d="M498.7,272.1c0-3.8,2.5-6.5,6.2-6.5c3.5,0,5.9,2.6,5.9,6.4c0,0.4,0,0.8-0.1,1.1h-8.6c0.4,1.8,1.6,2.5,3.1,2.5
			c1.1,0,2.2-0.4,3.1-1.2l1.9,2.1c-1.4,1.3-3.1,1.8-5.2,1.8C501.3,278.5,498.7,276,498.7,272.1z M507.5,271
			c-0.3-1.6-1.2-2.4-2.7-2.4c-1.5,0-2.5,0.9-2.7,2.4H507.5z"
          />
          <path
            className="st0"
            d="M512.5,277.1l1.3-2.5c1,0.7,2.3,1.2,4.1,1.2c1.5,0,2.3-0.4,2.3-1.1c0-0.6-0.6-0.9-2-1.1l-1-0.1
			c-2.8-0.4-4.2-1.6-4.2-3.7c0-2.5,1.9-4,5.1-4c1.9,0,3.5,0.4,5.1,1.2l-1.1,2.6c-1-0.5-2.5-1-3.9-1c-1.2,0-1.9,0.4-1.9,1.1
			c0,0.7,0.9,0.9,2,1l1.1,0.2c2.6,0.4,4.1,1.6,4.1,3.6c0,2.5-2.1,4.1-5.6,4.1C516.3,278.5,514.1,278.2,512.5,277.1z"
          />
          <path
            className="st0"
            d="M525.8,276.1c0-1.3,1.1-2.4,2.5-2.4c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.5-2.4,2.5
			C527,278.5,525.8,277.4,525.8,276.1z"
          />
          <path
            className="st0"
            d="M540,278.2v-17.6h13v3.2h-9.5v3.9h9.1v3.2h-9.1v4h9.6v3.2H540z"
          />
          <path
            className="st0"
            d="M556.5,278.2V266h3.2v1.4c0.9-1.3,2.2-1.8,3.6-1.8c1.8,0,3.2,0.8,4,2.1c0.9-1.5,2.5-2.1,4.1-2.1
			c2.9,0,4.8,1.9,4.8,4.8v7.8H573v-6.7c0-1.8-0.9-2.7-2.4-2.7c-1.4,0-2.6,0.9-2.6,2.8v6.7h-3.3v-6.7c0-1.8-0.9-2.7-2.4-2.7
			c-1.4,0-2.6,0.9-2.6,2.8v6.7H556.5z"
          />
          <path
            className="st0"
            d="M579.6,283.2V266h3.2v1.3c0.8-1,2-1.7,3.6-1.7c3.4,0,5.9,2.8,5.9,6.4c0,3.7-2.5,6.4-5.9,6.4
			c-1.6,0-2.8-0.6-3.6-1.6v6.3H579.6z M589,272.1c0-1.9-1.2-3.4-3.1-3.4c-1.9,0-3.1,1.4-3.1,3.4s1.3,3.4,3.1,3.4
			C587.8,275.5,589,274,589,272.1z"
          />
          <path
            className="st0"
            d="M594.3,272.1c0-3.7,2.8-6.4,6.6-6.4c3.8,0,6.6,2.7,6.6,6.4s-2.8,6.4-6.6,6.4
			C597.1,278.5,594.3,275.8,594.3,272.1z M604.1,272.1c0-2-1.4-3.4-3.2-3.4c-1.8,0-3.2,1.4-3.2,3.4c0,2,1.4,3.4,3.2,3.4
			C602.7,275.5,604.1,274.1,604.1,272.1z"
          />
          <path
            className="st0"
            d="M612.5,278.2l-4.1-12.2h3.5l2.6,8.5l2.7-8.5h3.5l2.8,8.6l2.6-8.6h3.4l-4.1,12.2h-3.7l-2.7-8.2l-2.7,8.2H612.5
			z"
          />
          <path
            className="st0"
            d="M630.5,272.1c0-3.8,2.5-6.5,6.2-6.5c3.5,0,5.9,2.6,5.9,6.4c0,0.4,0,0.8-0.1,1.1h-8.6c0.4,1.8,1.6,2.5,3.1,2.5
			c1.1,0,2.2-0.4,3.1-1.2l1.9,2.1c-1.4,1.3-3.1,1.8-5.2,1.8C633.1,278.5,630.5,276,630.5,272.1z M639.3,271
			c-0.3-1.6-1.2-2.4-2.7-2.4c-1.5,0-2.5,0.9-2.7,2.4H639.3z"
          />
          <path
            className="st0"
            d="M645.3,278.2V266h3.2v1.3c0.8-1.1,1.9-1.7,3.4-1.7c0.7,0,1.3,0.1,1.7,0.3l-0.5,3.3c-0.5-0.2-1.3-0.4-2-0.4
			c-1.5,0-2.6,0.9-2.6,2.7v6.6H645.3z"
          />
          <path
            className="st0"
            d="M655.2,262c0-1.2,0.9-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
			C656.1,264.1,655.2,263.2,655.2,262z M655.7,278.2V266h3.3v12.2H655.7z"
          />
          <path
            className="st0"
            d="M662.4,278.2V266h3.2v1.4c0.9-1.3,2.2-1.8,3.6-1.8c2.8,0,4.7,1.9,4.7,4.8v7.8h-3.3v-6.7
			c0-1.8-0.9-2.7-2.4-2.7c-1.4,0-2.6,0.9-2.6,2.8v6.7H662.4z"
          />
          <path
            className="st0"
            d="M677.2,282l1.2-2.7c1.2,0.8,2.6,1.2,4,1.2c2,0,3.5-1.1,3.5-3.2v-0.9c-0.8,1-2.1,1.6-3.7,1.6
			c-3,0-5.6-2.5-5.6-6.2c0-3.7,2.6-6.2,5.6-6.2c1.7,0,2.9,0.7,3.8,1.7V266h3.2v11.2c0,3.9-2.6,6.3-6.6,6.3
			C680.6,283.5,678.5,283,677.2,282z M686,271.9c0-1.8-1.2-3.2-3.1-3.2c-1.9,0-3.1,1.4-3.1,3.2c0,1.8,1.2,3.2,3.1,3.2
			C684.7,275.1,686,273.7,686,271.9z"
          />
          <path
            className="st0"
            d="M698.8,283.2V266h3.2v1.3c0.8-1,2-1.7,3.6-1.7c3.4,0,5.9,2.8,5.9,6.4c0,3.7-2.5,6.4-5.9,6.4
			c-1.6,0-2.8-0.6-3.6-1.6v6.3H698.8z M708.1,272.1c0-1.9-1.2-3.4-3.1-3.4c-1.9,0-3.1,1.4-3.1,3.4s1.3,3.4,3.1,3.4
			C706.9,275.5,708.1,274,708.1,272.1z"
          />
          <path
            className="st0"
            d="M713.5,272.1c0-3.7,2.5-6.4,5.9-6.4c1.7,0,2.8,0.7,3.6,1.7V266h3.2v12.2H723v-1.3c-0.8,1-2,1.7-3.6,1.7
			C716,278.5,713.5,275.8,713.5,272.1z M723.1,272.1c0-1.9-1.3-3.4-3.1-3.4c-1.9,0-3.1,1.5-3.1,3.4s1.2,3.4,3.1,3.4
			C721.9,275.5,723.1,274,723.1,272.1z"
          />
          <path
            className="st0"
            d="M730.7,273.6v-4.7h-2.4V266h2.4v-3.6h3.3v3.6h4.4v2.9H734v4.5c0,1.5,0.8,2.1,1.8,2.1c0.8,0,1.6-0.4,2.3-0.8
			l1.2,2.6c-1.1,0.7-2.3,1.3-4,1.3C732.2,278.5,730.7,276.8,730.7,273.6z"
          />
          <path
            className="st0"
            d="M740.8,262c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1S740.8,263.2,740.8,262z
			 M741.3,278.2V266h3.3v12.2H741.3z"
          />
          <path
            className="st0"
            d="M747.2,272.1c0-3.8,2.5-6.5,6.2-6.5c3.5,0,5.9,2.6,5.9,6.4c0,0.4,0,0.8-0.1,1.1h-8.6c0.4,1.8,1.6,2.5,3.1,2.5
			c1.1,0,2.2-0.4,3.1-1.2l1.9,2.1c-1.4,1.3-3.1,1.8-5.2,1.8C749.8,278.5,747.2,276,747.2,272.1z M756,271c-0.3-1.6-1.2-2.4-2.7-2.4
			c-1.5,0-2.5,0.9-2.7,2.4H756z"
          />
          <path
            className="st0"
            d="M762,278.2V266h3.2v1.4c0.9-1.3,2.2-1.8,3.6-1.8c2.8,0,4.7,1.9,4.7,4.8v7.8h-3.3v-6.7c0-1.8-0.9-2.7-2.4-2.7
			c-1.4,0-2.6,0.9-2.6,2.8v6.7H762z"
          />
          <path
            className="st0"
            d="M777.8,273.6v-4.7h-2.4V266h2.4v-3.6h3.3v3.6h4.4v2.9h-4.4v4.5c0,1.5,0.8,2.1,1.8,2.1c0.8,0,1.6-0.4,2.3-0.8
			l1.2,2.6c-1.1,0.7-2.3,1.3-4,1.3C779.4,278.5,777.8,276.8,777.8,273.6z"
          />
          <path
            className="st0"
            d="M788,277.1l1.3-2.5c1,0.7,2.3,1.2,4.1,1.2c1.5,0,2.3-0.4,2.3-1.1c0-0.6-0.6-0.9-2-1.1l-1-0.1
			c-2.8-0.4-4.2-1.6-4.2-3.7c0-2.5,1.9-4,5.1-4c1.9,0,3.5,0.4,5.1,1.2l-1.1,2.6c-1-0.5-2.5-1-3.9-1c-1.2,0-1.9,0.4-1.9,1.1
			c0,0.7,0.9,0.9,2,1l1.1,0.2c2.6,0.4,4.1,1.6,4.1,3.6c0,2.5-2.1,4.1-5.6,4.1C791.7,278.5,789.5,278.2,788,277.1z"
          />
          <path
            className="st0"
            d="M801.3,276.1c0-1.3,1.1-2.4,2.5-2.4c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.5-2.4,2.5
			C802.4,278.5,801.3,277.4,801.3,276.1z"
          />
        </g>
      </g>
    </svg>
  );
};
export default EmpowerMeLogo;
