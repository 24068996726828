import React, { useState, useLayoutEffect } from 'react';

interface RippleProps {
  x: number;
  y: number;
  size: number;
}

/**
 * Ripple clean up hook.
 * @param {number} rippleCount
 * @param {number} duration
 * @param {Function} cleanUpFunction
 */
const useDebouncedRippleCleanUp = (
  rippleCount: number,
  duration: number,
  cleanUpFunction: () => void
) => {
  useLayoutEffect(() => {
    let bounce: NodeJS.Timeout = setTimeout(() => {}, 0);
    if (rippleCount > 0) {
      clearTimeout(bounce);
      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 4);
    }
    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

/**
 * Ripple effect.
 */
const Ripple: React.FC<{ duration?: number }> = ({ duration = 500 }) => {
  const [rippleArray, setRippleArray] = useState<RippleProps[]>([]);
  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([]);
  });

  /**
   * Add Ripple.
   */
  const addRipple = (event: React.MouseEvent<HTMLElement>): void => {
    const rippleContainer = event?.currentTarget?.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;
    const newRipple = {
      x,
      y,
      size,
    };
    setRippleArray([...rippleArray, newRipple]);
  };

  /**
   * Render.
   */
  return (
    <div className="ripple" onMouseDown={addRipple} data-testid="ripple">
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => {
          return (
            <span
              key={'span' + index}
              style={{
                top: ripple.y ?? 0,
                left: ripple.x ?? 0,
                width: ripple.size,
                height: ripple.size,
              }}
            />
          );
        })}
    </div>
  );
};
export default Ripple;
