/** @format */

import React from 'react';

interface FaqProps {
  faq: {
    answer: string;
    faq_category: string;
    faq_key: string;
    question: string;
  };
}

/**
 * Faq.
 */
const Faq: React.FC<{
  questionId: string;
  faqList: FaqProps[];
  faqLink?: boolean;
}> = ({ questionId, faqList, faqLink = false }) => {
  if (faqList && faqList.length > 0) {
    return faqList.map((item) => {
      if (!item.faq) return null;

      const {
        faq_key: key,
        faq_category: category,
        question,
        answer,
      } = item.faq;

      if (
        key === questionId ||
        questionId === 'all' ||
        category === questionId
      ) {
        if (faqLink) {
          const link = `#${key}`;
          return (
            <li key={key}>
              <a className="faq-anchor" href={link}>
                {question}
              </a>
            </li>
          );
        }
        return (
          <div key={key} id={key} className="faq">
            <h3>{question}</h3>
            <p>{answer}</p>
          </div>
        );
      }
      return null;
    });
  }
  return null;
};
export default Faq;
