/** @format */

import React from 'react';
import { getSafeName } from '@utilities';

interface TermProps {
  term: {
    term_definition: string;
    term_group: string;
    term_name: string;
  };
}

/**
 * Glossary List.
 *
 * @format
 */
const GlossaryList: React.FC<{
  terms: TermProps[];
  language?: string;
  glossaryLink?: boolean;
}> = ({ terms, glossaryLink = false, language = '' }) => {
  const glossaryLinkLetters: Array<string> = [];

  return terms.map((glossaryTerm, index: number) => {
    const {
      term_name: termName,
      term_definition: definition,
      term_group: termGroup,
    } = glossaryTerm.term;
    const safeName = getSafeName(termName);

    if (safeName) {
      const termLetters: string =
        language === 'ja' ? termGroup : safeName.split('')[0];
      let currentLetter: JSX.Element = <></>;

      // Glossary Link
      if (glossaryLink) {
        if (!glossaryLinkLetters.includes(termLetters)) {
          glossaryLinkLetters.push(termLetters);
          return (
            <a
              href={`#${termLetters}`}
              className="letter-link"
              key={termLetters}
            >
              {termLetters}
            </a>
          );
        }
      } else {
        // Glossary Entry
        if (!glossaryLinkLetters.includes(termLetters)) {
          currentLetter = (
            <div id={termLetters} className="glossary-letter">
              {termLetters}
            </div>
          );
        }

        const termLetterClass = !glossaryLinkLetters.includes(termLetters)
          ? 'letter-start glossary-term'
          : 'glossary-term';

        glossaryLinkLetters.push(termLetters);

        return (
          <div key={index} className={termLetterClass}>
            {currentLetter}
            <dt id={safeName}>{termName}</dt>
            <dd>{definition}</dd>
          </div>
        );
      }
    }
    return null;
  });
};
export default GlossaryList;
