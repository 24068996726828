import React, { Children } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { useWindowSize } from '@hooks';
import { AccordionWrapper, AccordionItem } from '@components';

/**
 * TabsWrapper.
 */
export const TabsWrapper: React.FC<{
  children: React.ReactNode;
  tabMenuItems: string[];
  tabStyle?: string;
}> = ({ children, tabMenuItems, tabStyle = 'fixed-width' }) => {
  const { width } = useWindowSize();

  if (tabMenuItems && tabMenuItems.length > 0 && children) {
    /**
     * Accordion render at smaller screens.
     */
    if (width < 768) {
      return (
        <AccordionWrapper multiple={false} accordionStyle="tabbed">
          {Children.map(children, (child, index) => {
            return (
              <AccordionItem title={tabMenuItems[index]}>{child}</AccordionItem>
            );
          })}
        </AccordionWrapper>
      );
    }

    /**
     * Tabs render.
     */
    return (
      <Tabs.Root className={`tabs-root ${tabStyle}`} defaultValue={'tab-1'}>
        <TabsList tabMenuItems={tabMenuItems} />
        {children}
      </Tabs.Root>
    );
  }
};

/**
 * Tabs list.
 */
export const TabsList: React.FC<{
  tabMenuItems: string[];
}> = ({ tabMenuItems }) => {
  /**
   * Render.
   */
  return (
    <Tabs.List className={`tabs-list`}>
      {tabMenuItems.map((title, index) => {
        return (
          <Tabs.Trigger
            className="tabs-trigger"
            value={`tab-${index + 1}`}
            key={`tabs-trigger-key-${index}`}
          >
            <h3>{title}</h3>
          </Tabs.Trigger>
        );
      })}
    </Tabs.List>
  );
};

export const TabsContent: React.FC<{
  children: React.ReactNode;
  value: string;
}> = ({ children, value }) => {
  const { width } = useWindowSize();

  if (width < 768) {
    return <>{children}</>;
  }

  return (
    <Tabs.Content className="tabs-content" value={value}>
      {children}
    </Tabs.Content>
  );
};

const exportTabs = { TabsWrapper, TabsContent, TabsList };
export default exportTabs;
