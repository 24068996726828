import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from '@base-components';
import { PageFrontmatter } from '@interfaces';

/**
 * Carousel Slide.
 */
const Slide: React.FC<{
  slideData: PageFrontmatter;
  backgroundColor: string;
}> = ({ slideData, backgroundColor }) => {
  const {
    title,
    teaser,
    path,
    carousel_image: carouselImage,
    cancer_type: cancerType,
  } = slideData;
  const firstName = title.split(' ')[0];

  /**
   * Render.
   */
  if (carouselImage) {
    const safeImage = getImage(carouselImage);
    if (safeImage) {
      return (
        <div className="slide-wrapper">
          <div className="img-container">
            <GatsbyImage image={safeImage} alt={title} />
          </div>
          <div className="slide-container">
            <div
              className={`slide-content center-vertically background-${backgroundColor}`}
            >
              <div className="slide-content-container center-vertically">
                <h2>{title}</h2>
                <h3>{cancerType}</h3>
                <p>{teaser}</p>
                <Link
                  url={path}
                  ariaHidden={true}
                  featured={true}
                >{`Read ${firstName}'s story`}</Link>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};
export default Slide;
