/** @format */

import { Link } from 'gatsby';
import React, { useState, useMemo } from 'react';
// @ts-ignore
import { logoBlack } from '@images';
import { Menu, ResponsiveMenu } from '@components';
import { useWindowSize } from '@hooks';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useStateContext } from '@context';
import type { StateContextProps } from '@context';

/**
 * Header.
 */
const Header = (): JSX.Element => {
  const [stickyMenu, setStickyMenu] = useState<boolean>(false);
  const [showStickyMenu, setShowStickyMenu] = useState<boolean>(false);
  const { responsiveMenuOpen, setResponsiveMenuOpen }: StateContextProps =
    useStateContext();
  const { width } = useWindowSize();

  /**
   * Use Scroll Position.
   */
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (width < 1440) {
        const isSticky = currPos.y * -1 >= 20;
        if (isSticky !== stickyMenu) {
          setStickyMenu(isSticky);
        }

        const isShowing = currPos.y > prevPos.y;
        if (isShowing !== showStickyMenu) {
          setShowStickyMenu(isShowing);
        }

        if (responsiveMenuOpen) {
          setResponsiveMenuOpen(false);
        }
      } else {
        setShowStickyMenu(false);
        setStickyMenu(false);
      }
    },
    [stickyMenu, showStickyMenu],
    undefined,
    false,
    300
  );

  /**
   * Render.
   */
  return useMemo(
    () => (
      <header
        className={`patient-header header-wrapper ${
          stickyMenu ? `sticky-menu` : ``
        } ${showStickyMenu ? `show-sticky-menu` : ``}`}
      >
        <div className="header-container">
          <Link className="logo-black logo" title="Varian" to="/">
            <img src={logoBlack} alt="Varian" />
          </Link>
          {width >= 1440 ? <Menu /> : <ResponsiveMenu />}
        </div>
      </header>
    ),
    [stickyMenu, showStickyMenu, width]
  );
};
export default Header;
