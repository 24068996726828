/** @format */

import { getSafeName } from '@utilities';

/**
 * Setup Tooltip Hover.
 */
const setupTooltipHover = (termLink: HTMLAnchorElement, definition: string) => {
  if (termLink !== null) {
    const tooltip: HTMLSpanElement | null =
      termLink.querySelector('span.tooltip');
    termLink.addEventListener('mouseenter', () => {
      termLink.title = '';
      tooltip?.classList.add('show-tooltip');
    });
    termLink.addEventListener('mouseleave', () => {
      termLink.title = definition;
      tooltip?.classList.remove('show-tooltip');
    });
  }
};

/**
 * Generate Glossary Tooltip.
 */
const generateGlossaryTooltip = (
  termLink: HTMLAnchorElement,
  definition: string
) => {
  const tooltip = document.createElement('span');
  tooltip.classList.add('tooltip');
  tooltip.innerText = definition;
  tooltip.setAttribute('role', 'tooltip');
  termLink.insertAdjacentElement('beforeend', tooltip);
};

/**
 * Setup Glossary Links.
 */
export const setupGlossaryLinks = (): void => {
  const glossaryLinks: NodeListOf<HTMLAnchorElement> =
    document.querySelectorAll('a.glossary-term');
  const language = document.documentElement.lang;
  if (glossaryLinks.length > 0) {
    [].forEach.call(glossaryLinks, (termLink: HTMLAnchorElement) => {
      const term = termLink.innerText;
      const definition = termLink.title;
      const safeTerm = getSafeName(term);
      const glossaryUrl = `/${language}/glossary#${safeTerm}`;
      termLink.href = glossaryUrl;
      generateGlossaryTooltip(termLink, definition);
      setupTooltipHover(termLink, definition);
    });
  }
};

/**
 * Look For Glossary Term.
 */
export const termLookup = (): void => {
  const glossary: HTMLElement | null = document.getElementById('glossary');
  if (glossary) {
    const url = window.location.href;
    const term = url.split('#');
    if (term.length > 1) {
      const termLookup: HTMLElement | null = document.getElementById(term[1]);
      if (termLookup !== null) {
        const bodyRectangle = document.body.getBoundingClientRect();
        const termRectangle = termLookup.getBoundingClientRect();
        const offsetPosition = termRectangle.top - bodyRectangle.top;
        window.scrollTo(0, offsetPosition);
      }
    }
  }
};
