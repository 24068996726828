/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { JaMenu } from '@legacy-components';
import { useSiteData, DataContextProps } from '@context';
import {
  buildNavigationData,
  getSafeTitle,
  getNavigationOrder,
} from '@utilities';
import MenuItem from './menu-item';

/**
 * Menu.
 */
const Menu = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const {
      currentLocale,
      allLegacyPages,
      allEmpowerMePages,
      currentPageType,
    } = siteData;
    const language = currentLocale ?? 'en';
    const data =
      currentPageType === 'legacy' ? allLegacyPages : allEmpowerMePages;
    const navigationData = buildNavigationData(data, language, currentPageType);
    const navigationOrder = getNavigationOrder(currentPageType);

    if (navigationData) {
      /**
       * Japan Menu.
       */
      if (currentLocale === 'ja') {
        return <JaMenu />;
      }

      /**
       * Render.
       */
      return (
        <nav className="top-navigation">
          <ul>
            {navigationData &&
              navigationOrder.map((navigationKey: string) => {
                if (navigationKey !== 'no-navigation') {
                  return (
                    <MenuItem
                      linkTitle={getSafeTitle(navigationKey, siteData)}
                      path={`/${language}/${navigationKey}/`}
                      // @ts-ignore
                      menuData={navigationData[navigationKey]}
                      pageType={currentPageType}
                      key={navigationKey}
                    />
                  );
                }
                return null;
              })}
            <li className="top-navigation-item search">
              <Link to={`/${language}/search`}>
                <i className="fas fa-search" />
              </Link>
            </li>
          </ul>
        </nav>
      );
    }
  }
  return null;
};
export default Menu;
