import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '@utilities';
import { usePageData, PageContextProps } from '@context';

/**
 * Simple Banner.
 */
const SimpleBanner: React.FC<{
  bannerTitle: string;
  backgroundColor: string;
  textOrientationLeft?: boolean;
  bannerAltTag?: string;
  largerTextArea?: boolean;
  storybookImage?: string;
}> = ({
  bannerTitle,
  backgroundColor,
  textOrientationLeft = true,
  bannerAltTag,
  largerTextArea = false,
  storybookImage,
}) => {
  const { pageData }: PageContextProps = usePageData();
  if (!pageData) return null;

  const safeImage = getImageData('simple_banner_image', pageData);

  /**
   * Render.
   */
  if (safeImage) {
    return (
      <section
        className={`banner-wrapper background-${backgroundColor} simple-banner banner-text-${
          textOrientationLeft ? 'left' : 'right'
        } ${largerTextArea ? 'larger-text-area' : ''}`}
      >
        <div className="blade-wrapper">
          <div className="blade-container">
            <div className="text-container center-vertically">
              <h2>{bannerTitle}</h2>
            </div>
          </div>
        </div>
        <div className="img-container">
          {storybookImage && storybookImage !== '' ? (
            <div className="gatsby-image-wrapper">
              <img
                src={storybookImage}
                alt={bannerAltTag ?? bannerTitle}
                className="storybook-img"
              />
            </div>
          ) : (
            <GatsbyImage image={safeImage} alt={bannerAltTag ?? bannerTitle} />
          )}
        </div>
      </section>
    );
  }
  return null;
};
export default SimpleBanner;
