import React from 'react';
import * as RadixForm from '@radix-ui/react-form';

export interface FieldDetails {
  label: string;
  type: string;
  name: string;
  required: boolean;
  valueMissing?: string;
  typeMismatch?: string;
}

/**
 * Form Field.
 */
const FormField: React.FC<{ fieldDetails: FieldDetails }> = ({
  fieldDetails,
}) => {
  const { label, valueMissing, typeMismatch, type, name, required } =
    fieldDetails;

  return (
    <RadixForm.Field className="form-field-group" name={name}>
      <RadixForm.Control asChild>
        {type === 'textarea' ? (
          <textarea
            required={required}
            rows={5}
            placeholder={label}
            className="form-field"
          />
        ) : (
          <input
            type={type}
            required={required}
            placeholder={label}
            className="form-field"
          />
        )}
      </RadixForm.Control>
      <RadixForm.Label className="form-label">{label}</RadixForm.Label>
      <div className="form-message-container">
        {valueMissing && (
          <RadixForm.Message className="form-message" match="valueMissing">
            {valueMissing}
          </RadixForm.Message>
        )}
        {typeMismatch && (
          <RadixForm.Message className="form-message" match="typeMismatch">
            {typeMismatch}
          </RadixForm.Message>
        )}
      </div>
    </RadixForm.Field>
  );
};
export default FormField;
