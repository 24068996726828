import React, { useState } from 'react';
import Slider from 'react-slick';
import Slide from './slide';
import { useSiteData, DataContextProps } from '@context';

interface CarouselSlide {
  pageId: string;
  backgroundColor: string;
  slideIndex: number;
}

/**
 * Custom arrow for carousel. Added classes to help with styling.
 */
const CustomArrow: React.FC<{ className?: string; onClick?: () => {} }> = ({
  className,
  onClick,
}) => {
  return (
    <div className="arrow-wrapper">
      <div className="arrow-container">
        <button className={className} onClick={onClick} />
      </div>
    </div>
  );
};

/**
 * Carousel.
 */
const Carousel: React.FC<{ slides: CarouselSlide[] }> = ({ slides }) => {
  const { siteData }: DataContextProps = useSiteData();
  const [sliderBackgroundColor, setSliderBackgroundColor] =
    useState<string>('accent-blue');

  if (siteData) {
    const { allEmpowerMePages } = siteData;
    const sortedSlides = slides.sort((a, b) => {
      if (a.slideIndex < b.slideIndex) {
        return -1;
      }
      if (a.slideIndex > b.slideIndex) {
        return 1;
      }
      return 0;
    });

    const settings = {
      dots: true,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      nextArrow: <CustomArrow />,
      prevArrow: <CustomArrow />,
      afterChange: (current: number) => {
        setSliderBackgroundColor(sortedSlides[current].backgroundColor);
      },
    };

    /**
     * Render.
     */
    if (allEmpowerMePages?.nodes && allEmpowerMePages?.nodes.length > 0) {
      return (
        <Slider {...settings} className={`background-${sliderBackgroundColor}`}>
          {sortedSlides.map((slide) => {
            if (allEmpowerMePages.nodes) {
              for (const pageData of allEmpowerMePages.nodes) {
                const { frontmatter } = pageData;
                if (slide.pageId === frontmatter.id) {
                  return (
                    <Slide
                      slideData={frontmatter}
                      backgroundColor={slide.backgroundColor}
                      key={slide.pageId}
                    />
                  );
                }
              }
            }
            return null;
          })}
        </Slider>
      );
    }
  }
};
export default Carousel;
