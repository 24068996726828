/** @format */

import React, { useEffect, useState } from 'react';
import { Link } from '@base-components';
import {
  getSafeTitle,
  mapObject,
  isObjectEmptyNullOrUndefined,
} from '@utilities';
import {
  useStateContext,
  StateContextProps,
  DataContextProps,
  useSiteData,
} from '@context';
import type { NavigationData } from '@interfaces';

/**
 * Responsive Menu Item.
 */
const ResponsiveMenuItem: React.FC<{
  menuData?: NavigationData;
  title: string | boolean;
  path: string;
  pageType?: string;
  primaryNavigation?: boolean;
}> = ({ menuData, title, path, pageType }) => {
  const [ariaExpanded, setAriaExpanded] = useState<boolean>(false);
  const { responsiveMenuOpen, setResponsiveMenuOpen }: StateContextProps =
    useStateContext();
  const { siteData }: DataContextProps = useSiteData();
  const menuItems = menuData?.menuItems;
  const hasSubmenu = !isObjectEmptyNullOrUndefined(menuItems);

  /**
   * Close responsive menu and reset dropdown menus.
   */
  const resetResponsiveMenu = (): void => {
    setAriaExpanded(false);
    setResponsiveMenuOpen(false);
  };

  /**
   * On responsive menu close, only close menu items that are expanded.
   */
  useEffect(() => {
    if (!responsiveMenuOpen && ariaExpanded) {
      setAriaExpanded(false);
    }
  }, [responsiveMenuOpen, ariaExpanded]);

  /**
   * Render.
   */
  return (
    <li
      className={`${hasSubmenu ? 'has-submenu' : ''} ${
        ariaExpanded ? 'menu-open' : ''
      }`}
    >
      {hasSubmenu ? (
        <button
          aria-haspopup="true"
          aria-expanded={ariaExpanded}
          onClick={() => setAriaExpanded(!ariaExpanded)}
          className="featured-link"
        >
          {title}
        </button>
      ) : (
        <Link url={path} onLinkClick={() => resetResponsiveMenu()}>
          {title}
        </Link>
      )}

      {hasSubmenu && (
        <div className="submenu-container">
          <div className="submenu">
            <button
              type="button"
              className="back-button"
              onClick={() => setAriaExpanded(!ariaExpanded)}
            >
              <i className="fas fa-angle-left" />
              Back
            </button>
            <h3>{title}</h3>
            <ul>
              {menuItems &&
                siteData &&
                mapObject(menuItems, (key: string) => {
                  const { menu, id, path } = menuItems[key];
                  const titleKey = pageType === 'legacy' ? menu : id;
                  if (key !== 'no-navigation' && path) {
                    return (
                      <ResponsiveMenuItem
                        title={getSafeTitle(titleKey, siteData)}
                        path={path}
                        primaryNavigation={false}
                        key={key}
                      />
                    );
                  }
                })}
            </ul>
          </div>
        </div>
      )}
    </li>
  );
};
export default ResponsiveMenuItem;
