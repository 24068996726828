/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { useSiteData, DataContextProps } from '@context';
import type { MarkdownRemarkFrontmatter } from '@interfaces';

interface StoryProps {
  node: {
    frontmatter: MarkdownRemarkFrontmatter;
    id: string;
  };
}

/**
 * Get Featured Story.
 */
const getFeaturedStory = (
  featuredStories: string[],
  currentLanguage: string,
  story: StoryProps
): boolean => {
  let showStory = false;
  const { language, id } = story.node.frontmatter;
  if (language === currentLanguage) {
    featuredStories.forEach((featuredStory) => {
      if (featuredStory === id) {
        showStory = true;
      }
    });
  }
  return showStory;
};

/**
 * Featured Stories.
 */
const FeaturedStories: React.FC<{
  featuredStories: string[];
  isVisible?: boolean;
}> = ({ featuredStories, isVisible }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { translations, currentLocale, allLegacyPages } = siteData;
  const language = currentLocale ?? 'en';
  const pages = allLegacyPages ?? null;

  /**
   * Render.
   */
  if (pages) {
    const featuredStoryMarkup = pages?.edges?.map(
      // @ts-ignore
      (story: StoryProps, index: number) => {
        const {
          subtitle,
          path,
          teaser,
          read_more_button: readMore,
        } = story.node.frontmatter;

        const showStory = getFeaturedStory(featuredStories, language, story);

        if (showStory) {
          return (
            <li key={index}>
              <Link to={path}>
                <div className="featured-border" />
                <h3>{subtitle}</h3>
                <p>{teaser}</p>
                <div className="read-more">
                  {readMore}
                  <div className="dots">
                    <span>●</span>
                  </div>
                </div>
              </Link>
            </li>
          );
        }
        return null;
      }
    );

    return (
      <div className="featured-stories-wrapper">
        <div
          className={
            isVisible
              ? 'featured-stories-container is-visible'
              : 'featured-stories-container'
          }
        >
          <h2>{translations.patient_stories}</h2>
          <ul>{featuredStoryMarkup}</ul>
        </div>
      </div>
    );
  }
  return null;
};
export default FeaturedStories;
