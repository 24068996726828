/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { useSiteData, DataContextProps } from '@context';

/**
 * Page List.
 */
const PageList: React.FC<{
  node?: string;
  content?: string;
  pageType: string;
}> = ({ node, content, pageType }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { translations, currentLocale, allLegacyPages } = siteData;
  const lang = currentLocale ?? 'en';

  /**
   * Render.
   */
  if (allLegacyPages && pageType === 'listing-page') {
    return (
      <div className="page-list">
        {allLegacyPages &&
          allLegacyPages?.edges?.map((page, index: number) => {
            if (!page?.node?.frontmatter) return null;

            const {
              title,
              subtitle,
              language,
              path,
              teaser,
              node_type: nodeType,
              content_type: contentType,
              read_more_button: readMore,
            } = page?.node?.frontmatter;

            if (
              node === nodeType &&
              lang === language &&
              (content === contentType || content === 'all')
            ) {
              return (
                <div className="list-entry" key={index}>
                  <h2>{title}</h2>
                  {subtitle && <h3>{subtitle}</h3>}
                  {teaser && <p>{teaser}</p>}
                  <Link to={path} className="featured-link">
                    {readMore ?? translations['learn_more']}
                  </Link>
                </div>
              );
            }

            return null;
          })}
      </div>
    );
  }
  return null;
};

export default PageList;
