import React from 'react';
import { getSafeName } from '@utilities';
import * as Accordion from '@radix-ui/react-accordion';

/**
 *Accordion Item.
 */
export const AccordionItem: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const safeTitle = getSafeName(title);
  return (
    <Accordion.Item value={`item-${safeTitle}`}>
      <Accordion.Trigger className="accordion-trigger">
        <h3>{title}</h3>
      </Accordion.Trigger>
      <Accordion.Content className="accordion-content">
        {children}
      </Accordion.Content>
    </Accordion.Item>
  );
};

/**
 * Accordion Wrapper.
 */
export const AccordionWrapper: React.FC<{
  children: React.ReactNode;
  multiple?: boolean;
  accordionStyle?: string;
}> = ({ children, multiple = true, accordionStyle = 'basic' }) => {
  return (
    <Accordion.Root
      className={`accordion-wrapper ${accordionStyle}-accordion`}
      type={multiple ? 'multiple' : 'single'}
    >
      {children}
    </Accordion.Root>
  );
};
