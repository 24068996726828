import React, { ReactNode, forwardRef } from 'react';
import * as Select from '@radix-ui/react-select';

/**
 * Select Item.
 */
const SelectItem: React.FC<{ children: ReactNode }> = forwardRef(
  ({ children, ...props }, forwardedRef) => {
    return (
      // @ts-ignore
      <Select.Item className="select-item" {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
      </Select.Item>
    );
  }
);
export default SelectItem;
