import React from 'react';
import { useWindowSize } from '@hooks';
import { SelectList } from '@base-components';

/**
 * Ribbon Navigation.
 */
const RibbonNavigation: React.FC<{ navigationItems: string[] }> = ({
  navigationItems,
}) => {
  const { width } = useWindowSize();

  /**
   * Go to anchor.
   */
  const goToAnchor = (anchor: string): void => {
    const element = document.getElementById(anchor);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  /**
   * Smooth scroll.
   */
  const smoothScroll = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    anchor: string
  ): void => {
    event.preventDefault();
    goToAnchor(anchor);
  };

  /**
   * Render.
   */
  if (navigationItems && navigationItems.length > 0) {
    if (width !== 0 && width < 768) {
      return (
        <SelectList
          listItems={navigationItems}
          onClickEvent={goToAnchor}
          label="Jump to section"
        />
      );
    }

    return (
      <nav className="navigation-ribbon" aria-label="Page Navigation">
        <ul>
          {navigationItems.map((navigationItem) => {
            const safeName = navigationItem.toLowerCase().replace(/\s/g, '-');
            return (
              <li key={safeName}>
                <a
                  href={`#${safeName}`}
                  onClick={(event) => smoothScroll(event, safeName)}
                >
                  {navigationItem}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
  return null;
};
export default RibbonNavigation;
