/** @format */

import React from 'react';
import TrackVisibility from 'react-on-screen';
import { useWindowSize } from '@hooks';
import FeaturedStories from './featured-stories';

/**
 * Featured Stories Container.
 *
 * @param {Object} featuredStories
 */
const FeaturedStoriesContainer: React.FC<{ featuredStories: string[] }> = ({
  featuredStories,
}): JSX.Element => {
  const { width } = useWindowSize();

  if (width >= 992) {
    return (
      <TrackVisibility once offset={400}>
        <FeaturedStories featuredStories={featuredStories} />
      </TrackVisibility>
    );
  } else {
    return (
      <TrackVisibility once partialVisibility>
        <FeaturedStories featuredStories={featuredStories} />
      </TrackVisibility>
    );
  }
};
export default FeaturedStoriesContainer;
