import React, { useState } from 'react';
import { useSiteData, DataContextProps } from '@context';
import { AccordionWrapper, AccordionItem } from '@components';
import { Button } from '@base-components';
import { useStaticQuery, graphql } from 'gatsby';
import { getCurrentLanguageQuestions } from '@utilities';
import { FaqsFrontmatter } from '@interfaces';
import FaqsMenu from './faqs-menu';

/**
 * Faqs.
 */
const Faqs: React.FC<{ faqPrimaryGroups: string[] }> = ({
  faqPrimaryGroups,
}) => {
  const { siteData }: DataContextProps = useSiteData();
  const [activeGroup, setActiveGroup] = useState<string>('all');
  const data = useStaticQuery(graphql`
    {
      faqsList: allMarkdownRemark(
        filter: { frontmatter: { question_list: { eq: true } } }
      ) {
        edges {
          node {
            html
            id
            frontmatter {
              language
              questions {
                faq_group {
                  faq_category
                  faq_parent_category
                  faq_header
                  faq_download
                  citations
                  faqs {
                    faq {
                      faq_key
                      question
                      answer
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (siteData) {
    const { currentLocale, translations } = siteData;

    // @ts-ignore
    const faqList: FaqsFrontmatter = getCurrentLanguageQuestions(
      data,
      currentLocale ?? 'en'
    );

    /**
     * Render.
     */
    if (faqList?.questions && faqList?.questions.length > 0) {
      return (
        <div className="faqs-wrapper grid-1-2-columns">
          <FaqsMenu
            faqPrimaryGroups={faqPrimaryGroups}
            faqList={faqList}
            setActiveGroup={setActiveGroup}
            activeGroup={activeGroup}
          />
          <div className="faqs-container">
            {faqList.questions.map((faqQuestion) => {
              if (!faqQuestion?.faq_group) return null;

              const {
                faq_download: downloadUrl,
                faq_parent_category: parentCategory,
                faq_category: category,
                faq_header: header,
                faqs,
                citations,
              } = faqQuestion?.faq_group;

              if (activeGroup === 'all' || activeGroup === category) {
                return (
                  <div className="faq-group" key={category}>
                    <span className="section-subtitle">{parentCategory}</span>
                    <h2 className="section-title">{header}</h2>
                    <div className="link-container">
                      <Button
                        buttonType="button"
                        onButtonClick={() => {
                          window.open(downloadUrl, '_blank')?.focus();
                        }}
                      >
                        {translations.download_faq}
                      </Button>
                    </div>

                    <AccordionWrapper>
                      {faqs &&
                        faqs.length > 0 &&
                        faqs.map((faq, index) => {
                          const { answer, question } = faq.faq;
                          return (
                            <AccordionItem title={question} key={index}>
                              <div
                                dangerouslySetInnerHTML={{ __html: answer }}
                              />
                            </AccordionItem>
                          );
                        })}
                    </AccordionWrapper>

                    {citations && (
                      <div
                        className="citations"
                        dangerouslySetInnerHTML={{ __html: citations }}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
  }
  return null;
};
export default Faqs;
