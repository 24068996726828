/** @format */

import React, { ReactNode, useRef } from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';
import {
  TransitionGroup,
  Transition as ReactTransition,
} from 'react-transition-group';
import { Location } from '@interfaces';
import CSS from 'csstype';

const timeout = 300;

/**
 * Get Transition Styles.
 */
const getTransitionStyles: Partial<Record<TransitionStatus, CSS.Properties>> = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms var(--ease-out-curve)`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms var(--acceleration-curve)`,
    opacity: 0,
  },
};

/**
 * Transitions.
 */
const Transitions: React.FC<{ children: ReactNode; location: Location }> = ({
  children,
  location,
}) => {
  const nodeRef = useRef<HTMLElement>(null);
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
        nodeRef={nodeRef}
      >
        {(status) => (
          <div
            style={{
              ...getTransitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};
export default Transitions;
