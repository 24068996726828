/** @format */

import React, { useState, useRef, useEffect } from 'react';
import { getLanguageName } from '@utilities';
import { useSiteData, useStateContext } from '@context';
import LanguageLinks from './language-links';
import type { DataContextProps, StateContextProps } from '@context';

/**
 * Language Selector.
 *
 * @param {string} selectorType
 *   Language selector type used distinguish between responsive and desktop
 *   versions of the language selector.
 */
const LanguageSelector: React.FC<{ selectorType: string }> = ({
  selectorType,
}) => {
  const languageSelectorRef = useRef(null);
  const [ariaExpanded, setAriaExpanded] = useState<boolean>(false);
  const { responsiveMenuOpen, setResponsiveMenuOpen }: StateContextProps =
    useStateContext();
  const { siteData }: DataContextProps = useSiteData();

  /**
   * Handle click outside language selector.
   */
  const handleClickOutside = (event: MouseEvent): void => {
    const { target } = event;
    if (
      languageSelectorRef &&
      // @ts-ignore
      !languageSelectorRef.current?.contains(target) &&
      ariaExpanded
    ) {
      setAriaExpanded(false);
      setResponsiveMenuOpen(false);
    }
  };

  /**
   * Close selector once locale is changed.
   */
  useEffect(() => {
    if (!responsiveMenuOpen && ariaExpanded) {
      setAriaExpanded(false);
    }
  }, [responsiveMenuOpen, ariaExpanded]);

  /**
   * Setup click outside handler.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (siteData) {
    const { translations, currentLocale } = siteData;
    const language = currentLocale ?? 'en';
    const languageFull = getLanguageName(language);

    /**
     * Return.
     */
    if (translations) {
      if (selectorType === 'responsive') {
        return (
          <ul
            className={
              ariaExpanded
                ? 'open responsive-language-selector'
                : 'responsive-language-selector'
            }
            ref={languageSelectorRef}
          >
            <li onClick={() => setAriaExpanded(!ariaExpanded)}>
              <span>
                <i className="fas fa-globe-americas" />
                {translations[languageFull]}
              </span>
              <LanguageLinks />
            </li>
          </ul>
        );
      }

      return (
        <li
          className="language-dropdown"
          onMouseEnter={() => setAriaExpanded(true)}
          onMouseLeave={() => setAriaExpanded(false)}
          ref={languageSelectorRef}
        >
          <span>
            <i className="fas fa-globe-americas" />
            {translations[languageFull]}
          </span>
          <LanguageLinks />
        </li>
      );
    }
  }
  return null;
};
export default LanguageSelector;
