/** @format */

import React from 'react';
import { JaSideNavigation } from '@legacy-components';
import { useSiteData, DataContextProps } from '@context';
import { buildNavigationData, mapObject } from '@utilities';
import SideNavigationSubmenu from './side-navigation-submenu';

/**
 * Side Navigation
 */
const SideNavigation: React.FC<{
  pageId: string;
}> = ({ pageId }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { translations, currentLocale, allLegacyPages, currentPageType } =
    siteData;
  const language = currentLocale ?? 'en';
  const navigationData = buildNavigationData(
    allLegacyPages,
    language,
    currentPageType
  );
  let index = 0;

  if (navigationData) {
    /**
     * Japan side navigation.
     */
    if (currentLocale === 'ja') {
      return <JaSideNavigation />;
    }

    /**
     * Render.
     */
    return (
      <nav aria-hidden="true">
        <ul className="side-navigation">
          {navigationData &&
            mapObject(navigationData, (key: string) => {
              const safeKey = key.replace(new RegExp('-', 'g'), '_');
              // @ts-ignore
              const { menuItems } = navigationData[key];
              if (key !== 'no-navigation') {
                return (
                  <li className="navigation-header" key={index++}>
                    {translations[safeKey]}
                    <ul>
                      {menuItems &&
                        key !== 'no-navigation' &&
                        mapObject(menuItems, (submenuKey: string) => {
                          return (
                            <SideNavigationSubmenu
                              categoryMenuData={menuItems}
                              menuId={submenuKey}
                              key={index++}
                              pageId={pageId}
                            />
                          );
                        })}
                    </ul>
                  </li>
                );
              }
            })}
        </ul>
      </nav>
    );
  }
};
export default SideNavigation;
