/** @format */

import React from 'react';
import { Link } from '@base-components';
import { LanguageSelector } from '@components';
import { useSiteData, DataContextProps } from '@context';

/**
 * Top Header Bar.
 */
const TopHeaderBar: React.FC<{ pageType?: string }> = ({ pageType }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { translations, currentLocale } = siteData;
  const language = currentLocale ?? 'en';
  const subfolder = pageType === 'empower-me' ? 'resources/' : '';

  /**
   * Render.
   */
  return (
    <div className="top-bar-wrapper">
      <div className="top-bar-container">
        <nav>
          <ul>
            {language !== 'ja' && (
              <>
                {pageType === 'legacy' && (
                  <li>
                    <Link url="https://www.varian.com/oncology/treatment-locator">
                      {translations?.treatment_locator}
                    </Link>
                  </li>
                )}
                <li>
                  <Link url={`/${language}/${subfolder}faqs`}>
                    {translations?.faqs}
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link url={`/${language}/${subfolder}glossary`}>
                {translations?.glossary}
              </Link>
            </li>
            <LanguageSelector selectorType="top-bar" />
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default TopHeaderBar;
