import React from 'react';
import { useSiteData, DataContextProps } from '@context';
import { Link } from '@base-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

/**
 * Related Content.
 */
const RelatedContent: React.FC<{ ids: string[]; cardLayout: boolean }> = ({
  ids,
  cardLayout = false,
}) => {
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData) return null;

  const { allEmpowerMePages } = siteData;

  /**
   * Render.
   */
  if (allEmpowerMePages) {
    return (
      <div
        className={`related-content-wrapper${
          cardLayout ? '' : ' grid-2-columns'
        }`}
      >
        {allEmpowerMePages?.nodes?.map((story, index) => {
          const {
            title,
            teaser,
            id,
            path,
            page_image: pageImage,
          } = story.frontmatter;
          const image = getImage(pageImage);

          if (ids.includes(id) && image) {
            return (
              <div
                className={`${
                  cardLayout
                    ? 'related-content-card-layout'
                    : 'related-content-default'
                }`}
                key={`related-content-key-${index}`}
              >
                <div className="img-container">
                  <GatsbyImage image={image} alt={title} />
                </div>
                <div className="content-container">
                  <h3>{title}</h3>
                  {cardLayout && <p>{teaser}</p>}
                  <Link url={path} featured={true}>
                    Watch {title.split(' ')[0]}'s story
                  </Link>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
  return null;
};

export default RelatedContent;
