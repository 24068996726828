import React, { ReactNode, useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';

export interface LinkProps {
  url: string;
  children: ReactNode;
  featured?: boolean;
  onLinkClick?: () => void;
  ariaHidden?: boolean;
}

/**
 * Check if link is external.
 *
 * @param {string} url
 * @returns {boolean}
 */
export const isExternalLink = (url: string): boolean => {
  const tmp = document.createElement('a');
  tmp.href = url;
  return tmp.host !== window.location.host;
};

/**
 * Link.
 */
const Link: React.FC<LinkProps> = ({
  url,
  children,
  featured = false,
  onLinkClick,
  ariaHidden = false,
}) => {
  const [externalLink, setExternalLink] = useState<boolean>(true);

  /**
   * Check for external link.
   */
  useEffect(() => {
    setExternalLink(isExternalLink(url));
  }, [url]);

  /**
   * Render.
   */
  if (featured) {
    if (ariaHidden) {
      return externalLink ? (
        <div className="link-container">
          <a
            href={url}
            target={'_blank'}
            className="featured-link"
            tabIndex={-1}
            aria-hidden="true"
            onClick={onLinkClick}
            rel="noreferrer"
          >
            {children}
          </a>
        </div>
      ) : (
        <div className="link-container">
          <GatsbyLink
            to={url}
            target={'_self'}
            className="featured-link"
            tabIndex={-1}
            aria-hidden="true"
            onClick={onLinkClick}
          >
            {children}
          </GatsbyLink>
        </div>
      );
    }

    return externalLink ? (
      <div className="link-container">
        <a
          href={url}
          target={'_blank'}
          className="featured-link"
          onClick={onLinkClick}
          rel="noreferrer"
        >
          {children}
        </a>
      </div>
    ) : (
      <div className="link-container">
        <GatsbyLink
          to={url}
          target={'_self'}
          className="featured-link"
          onClick={onLinkClick}
        >
          {children}
        </GatsbyLink>
      </div>
    );
  }

  if (ariaHidden) {
    return externalLink ? (
      <a
        href={url}
        target={'_blank'}
        onClick={onLinkClick}
        tabIndex={-1}
        aria-hidden="true"
        rel="noreferrer"
      >
        {children}
      </a>
    ) : (
      <GatsbyLink
        to={url}
        target={'_self'}
        onClick={onLinkClick}
        tabIndex={-1}
        aria-hidden="true"
      >
        {children}
      </GatsbyLink>
    );
  }

  return externalLink ? (
    <a href={url} target={'_blank'} onClick={onLinkClick} rel="noreferrer">
      {children}
    </a>
  ) : (
    <GatsbyLink to={url} target={'_self'} onClick={onLinkClick}>
      {children}
    </GatsbyLink>
  );
};
export default Link;
