import React, { useState, useMemo } from 'react';
import { useSiteData, DataContextProps } from '@context';
import { Link } from '@base-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SelectList } from '@base-components';
import { getSafeName } from '@utilities';

/**
 * Resource List.
 */
const ResourceList: React.FC<{
  listType: string;
  isNode: boolean;
}> = ({ listType, isNode }) => {
  const { siteData }: DataContextProps = useSiteData();
  const [filterListItems, setFilterListItems] = useState<string[]>([]);
  const [selectCancerType, setSelectCancerType] = useState('');
  const allEmpowerMePages = siteData?.allEmpowerMePages ?? null;

  /**
   * Get Cancer Types.
   */
  const getCancerTypes = (): string[] => {
    const listItems: string[] = [];
    allEmpowerMePages?.nodes?.forEach((story) => {
      const { content_type: contentType, cancer_type: cancerType } =
        story.frontmatter;
      if (
        contentType === 'patient-stories' &&
        cancerType &&
        !listItems.includes(cancerType)
      ) {
        listItems.push(cancerType);
      }
    });
    return listItems;
  };

  /**
   * Check if Patient Story page.
   */
  useMemo(() => {
    if (!isNode && listType === 'patient-stories') {
      const cancerTypeList = getCancerTypes();
      setFilterListItems(cancerTypeList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!siteData) return null;
  const { translations } = siteData;

  /**
   * Render.
   */
  if (allEmpowerMePages) {
    const sortedStories = allEmpowerMePages?.nodes?.sort((a, b) => {
      if (a.frontmatter.title < b.frontmatter.title) {
        return -1;
      }
      if (a.frontmatter.title > b.frontmatter.title) {
        return 1;
      }
      return 0;
    });

    return (
      <div className="resource-list-wrapper">
        {/* Filter */}
        {filterListItems && filterListItems.length > 0 && (
          <div className="text-content">
            <SelectList
              listItems={['All Cancer Types', ...filterListItems]}
              onClickEvent={setSelectCancerType}
              label="Select an option"
            />
          </div>
        )}

        {/* Filter Items */}
        <div
          className={
            listType === 'patient-stories' ? 'grid-3-columns' : 'grid-2-columns'
          }
        >
          {sortedStories?.map((story, index: number) => {
            const {
              title,
              teaser,
              page_image: pageImage,
              content_type: contentType,
              node_type: nodeType,
              path,
              cancer_type: cancerType,
            } = story.frontmatter;
            const image = getImage(pageImage);
            const safeCancerType = cancerType ? getSafeName(cancerType) : '';

            /**
             * Node listing.
             */
            if (
              isNode &&
              listType === nodeType &&
              contentType !== 'patient-stories'
            ) {
              return (
                <div
                  className="small-vertical-margin small-gaps resource-listing"
                  key={index}
                >
                  <h3>{title}</h3>
                  <p>{teaser}</p>
                  <div className="link-container">
                    <Link url={path} featured={true}>
                      {translations.learn_more}
                    </Link>
                  </div>
                </div>
              );
            }

            /**
             * Patient Stories (Filtered card layout).
             */
            if (
              !isNode &&
              contentType === 'patient-stories' &&
              (safeCancerType.includes(selectCancerType) ||
                selectCancerType === 'all-cancer-types')
            ) {
              return (
                <div className={`resource-card`} key={index}>
                  {image && (
                    <div className="img-container">
                      <GatsbyImage image={image} alt={title} />
                    </div>
                  )}
                  <div className="text-container center-vertically">
                    <h3>{title}</h3>
                    <p>{teaser}</p>
                    <div className="link-container">
                      <Link url={path} featured={true}>
                        Watch {title.split(' ')[0]}'s story
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }

            return null;
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default ResourceList;
