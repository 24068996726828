/** @format */

import React, { useState, useLayoutEffect } from 'react';

/**
 * Scroll Button.
 */

/**
 * Back to Top.
 */
const BackToTop = (): JSX.Element => {
  const [visible, setVisible] = useState(true);

  /**
   * Scroll To Top.
   */
  const scrollToTop = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /**
   * Toggle Visible.
   */
  const toggleVisible = (): void => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  /**
   * Setup window event listener.
   */
  useLayoutEffect(() => {
    window.addEventListener('scroll', toggleVisible);
  }, []);

  /**
   * Render.
   */
  return (
    <button
      type="button"
      onClick={(event) => scrollToTop(event)}
      className={visible ? `show-button back-to-top` : `back-to-top`}
    />
  );
};
export default BackToTop;
