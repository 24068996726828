/** @format */

import React, { CSSProperties } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import TrackVisibility from 'react-on-screen';
import { Button } from '@base-components';

type LargeBannerProps = {
  buttonBackgroundColor?: string;
  buttonForegroundColor?: string;
  buttonLink?: string;
  buttonText?: string;
  gradientColorA?: string;
  gradientColorB?: string;
  gradientDirection?: number;
  image: any;
  imageOrientation?: string;
  subtitleColor?: string;
  text?: string;
  title: string;
  titleColor?: string;
};

/**
 * Large Banner Container.
 *
 * @param {Object} bannerData
 *   Banner display data.
 */
const LargeBannerContainer: React.FC<{ bannerData: LargeBannerProps }> = ({
  bannerData,
}) => {
  return (
    <TrackVisibility once partialVisibility>
      <LargeBanner bannerData={bannerData} />
    </TrackVisibility>
  );
};
export default LargeBannerContainer;

/**
 * Large Banner.
 *
 * @param {Object} bannerData
 *   Banner display data.
 * @param {bool} isVisible
 *   Boolean passed from the TrackVisibilty component.
 */
const LargeBanner: React.FC<{
  bannerData: LargeBannerProps;
  isVisible?: boolean;
}> = ({ bannerData, isVisible }) => {
  const {
    buttonLink,
    buttonText,
    gradientColorA,
    gradientColorB,
    gradientDirection,
    image,
    imageOrientation,
    subtitleColor,
    text,
    title,
    titleColor,
  } = bannerData;
  const safeImage = getImage(image);

  /**
   * Render.
   */
  if (safeImage && buttonLink) {
    return (
      <div
        className={
          isVisible ? `is-visible large-banner-wrapper` : `large-banner-wrapper`
        }
        style={
          {
            '--gradient-direction': `${gradientDirection}deg`,
            '--gradient-color-a': gradientColorA,
            '--gradient-color-b': gradientColorB,
          } as CSSProperties
        }
      >
        <div className={`large-banner-container ${imageOrientation}-align`}>
          <GatsbyImage image={safeImage} alt={title} />
          <div className="center-vertically">
            <div className="text-container">
              <h2
                style={{ '--title-color': titleColor } as CSSProperties}
                dangerouslySetInnerHTML={{ __html: title }}
              />

              <p style={{ '--subtitle-color': subtitleColor } as CSSProperties}>
                {text}
              </p>
              <Link to={buttonLink}>
                <Button buttonType="button" primary={true}>
                  {buttonText}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
