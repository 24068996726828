/** @format */

import React from 'react';
import { useSiteData, DataContextProps } from '@context';

/**
 * Quote.
 */
const Quote: React.FC<{ quote: string }> = ({ quote }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations } = siteData;

    if (quote) {
      return (
        <blockquote>
          <h2 className="visually-hidden">{translations?.patient_quote}</h2>
          {quote}
        </blockquote>
      );
    }
  }
  return null;
};
export default Quote;
