/**
 * Page Data Context.
 */
import React, { createContext, useContext, ReactNode } from 'react';
import { SiteMetadata, PageFrontmatter } from '@interfaces';

export type PageDataProps = {
  currentPage: {
    id: string;
    body: string;
    frontmatter: PageFrontmatter;
  };
  site: {
    siteMetadata: SiteMetadata;
  };
};

export type PageContextProps = {
  pageData: PageDataProps | null;
};

/**
 * Initial page context.
 */
export const PageContext = createContext<PageContextProps>({ pageData: null });

export const usePageData = () => useContext(PageContext);

/**
 * Page Provider.
 */
export const PageProvider: React.FC<{
  children?: ReactNode;
  pageData: PageDataProps;
}> = ({ children, pageData }) => {
  /**
   * Render.
   */
  return (
    <PageContext.Provider value={{ pageData }}>{children}</PageContext.Provider>
  );
};
