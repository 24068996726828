/** @format */

import React, { useEffect } from 'react';

/**
 * Brightcove Video Wrapper.
 * Loads the Brightcove scripts in the background before loading the video.
 *
 * @param {number} videoId Brightcove video ID.
 */
const VideoWrapper: React.FC<{ videoId: string }> = ({ videoId }) => {
  /**
   * Setup Video Script.
   */
  useEffect((): void => {
    if (videoId) {
      const videoScript = document.createElement('script');
      videoScript.src =
        '//players.brightcove.net/2418587696001/B165XyQL_default/index.min.js';
      videoScript.defer = true;
      document.body.appendChild(videoScript);
    }
  }, [videoId]);

  /**
   * Render.
   */
  if (videoId) {
    return (
      <div className="video-wrapper">
        <div className="video-container">
          <video
            data-video-id={videoId}
            data-account="2418587696001"
            data-player="B165XyQL"
            data-embed="default"
            data-application-id
            className="video-js"
            controls
          />
        </div>
      </div>
    );
  }
  return null;
};
export default VideoWrapper;
