exports.components = {
  "component---src-pages-markdown-en-404-mdx": () => import("./../../../src/pages/markdown/en/404.mdx" /* webpackChunkName: "component---src-pages-markdown-en-404-mdx" */),
  "component---src-pages-markdown-en-about-about-cancer-treatment-mdx": () => import("./../../../src/pages/markdown/en/about/about-cancer-treatment.mdx" /* webpackChunkName: "component---src-pages-markdown-en-about-about-cancer-treatment-mdx" */),
  "component---src-pages-markdown-en-cancer-types-anal-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/anal-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-anal-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-bladder-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/bladder-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-bladder-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-brain-tumors-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/brain-tumors.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-brain-tumors-mdx" */),
  "component---src-pages-markdown-en-cancer-types-breast-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/breast-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-breast-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-cancer-types-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/cancer-types.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-cancer-types-mdx" */),
  "component---src-pages-markdown-en-cancer-types-cervical-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/cervical-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-cervical-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-colorectal-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/colorectal-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-colorectal-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-endometrial-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/endometrial-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-endometrial-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-esophagus-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/esophagus-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-esophagus-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-head-and-neck-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/head-and-neck-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-head-and-neck-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-kidney-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/kidney-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-kidney-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-liver-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/liver-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-liver-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-lung-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/lung-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-lung-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-lymphoma-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/lymphoma.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-lymphoma-mdx" */),
  "component---src-pages-markdown-en-cancer-types-metastatic-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/metastatic-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-metastatic-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-pancreatic-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/pancreatic-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-pancreatic-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-pediatric-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/pediatric-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-pediatric-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-prostate-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/prostate-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-prostate-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-sarcoma-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/sarcoma.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-sarcoma-mdx" */),
  "component---src-pages-markdown-en-cancer-types-skin-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/skin-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-skin-cancer-mdx" */),
  "component---src-pages-markdown-en-cancer-types-stomach-cancer-mdx": () => import("./../../../src/pages/markdown/en/cancer-types/stomach-cancer.mdx" /* webpackChunkName: "component---src-pages-markdown-en-cancer-types-stomach-cancer-mdx" */),
  "component---src-pages-markdown-en-index-en-mdx": () => import("./../../../src/pages/markdown/en/index.en.mdx" /* webpackChunkName: "component---src-pages-markdown-en-index-en-mdx" */),
  "component---src-pages-markdown-en-index-mdx": () => import("./../../../src/pages/markdown/en/index.mdx" /* webpackChunkName: "component---src-pages-markdown-en-index-mdx" */),
  "component---src-pages-markdown-en-resources-cosmo-for-kids-mdx": () => import("./../../../src/pages/markdown/en/resources/cosmo-for-kids.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-cosmo-for-kids-mdx" */),
  "component---src-pages-markdown-en-resources-educational-videos-mdx": () => import("./../../../src/pages/markdown/en/resources/educational-videos.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-educational-videos-mdx" */),
  "component---src-pages-markdown-en-resources-faqs-mdx": () => import("./../../../src/pages/markdown/en/resources/faqs.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-faqs-mdx" */),
  "component---src-pages-markdown-en-resources-glossary-mdx": () => import("./../../../src/pages/markdown/en/resources/glossary.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-glossary-mdx" */),
  "component---src-pages-markdown-en-resources-noona-app-mdx": () => import("./../../../src/pages/markdown/en/resources/noona-app.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-noona-app-mdx" */),
  "component---src-pages-markdown-en-resources-patient-guides-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-guides.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-guides-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-bobby-english-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/bobby-english.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-bobby-english-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-carmen-brown-marshall-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/carmen-brown-marshall.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-carmen-brown-marshall-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-jennifer-yarnell-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/jennifer-yarnell.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-jennifer-yarnell-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-judy-schwartz-haley-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/judy-schwartz-haley.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-judy-schwartz-haley-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-luis-suarez-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/luis-suarez.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-luis-suarez-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-mahinder-kotigari-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/mahinder-kotigari.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-mahinder-kotigari-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-manju-katyal-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/manju-katyal.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-manju-katyal-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-mdx" */),
  "component---src-pages-markdown-en-resources-patient-stories-rita-bhalla-mdx": () => import("./../../../src/pages/markdown/en/resources/patient-stories/rita-bhalla.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-patient-stories-rita-bhalla-mdx" */),
  "component---src-pages-markdown-en-resources-questions-to-ask-your-doctor-mdx": () => import("./../../../src/pages/markdown/en/resources/questions-to-ask-your-doctor.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-questions-to-ask-your-doctor-mdx" */),
  "component---src-pages-markdown-en-resources-resources-mdx": () => import("./../../../src/pages/markdown/en/resources/resources.mdx" /* webpackChunkName: "component---src-pages-markdown-en-resources-resources-mdx" */),
  "component---src-pages-markdown-en-search-mdx": () => import("./../../../src/pages/markdown/en/search.mdx" /* webpackChunkName: "component---src-pages-markdown-en-search-mdx" */),
  "component---src-pages-markdown-en-treatments-brachytherapy-mdx": () => import("./../../../src/pages/markdown/en/treatments/brachytherapy.mdx" /* webpackChunkName: "component---src-pages-markdown-en-treatments-brachytherapy-mdx" */),
  "component---src-pages-markdown-en-treatments-external-beam-radiation-therapy-mdx": () => import("./../../../src/pages/markdown/en/treatments/external-beam-radiation-therapy.mdx" /* webpackChunkName: "component---src-pages-markdown-en-treatments-external-beam-radiation-therapy-mdx" */),
  "component---src-pages-markdown-en-treatments-interventional-oncology-mdx": () => import("./../../../src/pages/markdown/en/treatments/interventional-oncology.mdx" /* webpackChunkName: "component---src-pages-markdown-en-treatments-interventional-oncology-mdx" */),
  "component---src-pages-markdown-en-treatments-proton-therapy-mdx": () => import("./../../../src/pages/markdown/en/treatments/proton-therapy.mdx" /* webpackChunkName: "component---src-pages-markdown-en-treatments-proton-therapy-mdx" */),
  "component---src-pages-markdown-en-treatments-treatments-mdx": () => import("./../../../src/pages/markdown/en/treatments/treatments.mdx" /* webpackChunkName: "component---src-pages-markdown-en-treatments-treatments-mdx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../../src/templates/basic-page.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-404-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/404.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-404-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-about-about-cancer-treatment-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/about/about-cancer-treatment.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-about-about-cancer-treatment-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-anal-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/anal-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-anal-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-bladder-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/bladder-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-bladder-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-brain-tumors-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/brain-tumors.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-brain-tumors-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-breast-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/breast-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-breast-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-cancer-types-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/cancer-types.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-cancer-types-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-cervical-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/cervical-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-cervical-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-colorectal-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/colorectal-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-colorectal-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-endometrial-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/endometrial-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-endometrial-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-esophagus-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/esophagus-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-esophagus-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-head-and-neck-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/head-and-neck-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-head-and-neck-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-kidney-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/kidney-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-kidney-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-liver-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/liver-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-liver-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-lung-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/lung-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-lung-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-lymphoma-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/lymphoma.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-lymphoma-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-metastatic-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/metastatic-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-metastatic-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-pancreatic-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/pancreatic-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-pancreatic-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-pediatric-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/pediatric-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-pediatric-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-prostate-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/prostate-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-prostate-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-sarcoma-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/sarcoma.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-sarcoma-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-skin-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/skin-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-skin-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-stomach-cancer-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/cancer-types/stomach-cancer.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-cancer-types-stomach-cancer-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-index-en-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/index.en.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-index-en-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-index-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/index.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-index-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-cosmo-for-kids-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/cosmo-for-kids.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-cosmo-for-kids-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-educational-videos-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/educational-videos.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-educational-videos-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-faqs-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/faqs.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-faqs-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-glossary-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/glossary.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-glossary-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-noona-app-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/noona-app.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-noona-app-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-guides-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-guides.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-guides-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-bobby-english-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/bobby-english.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-bobby-english-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-carmen-brown-marshall-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/carmen-brown-marshall.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-carmen-brown-marshall-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-jennifer-yarnell-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/jennifer-yarnell.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-jennifer-yarnell-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-judy-schwartz-haley-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/judy-schwartz-haley.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-judy-schwartz-haley-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-luis-suarez-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/luis-suarez.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-luis-suarez-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-mahinder-kotigari-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/mahinder-kotigari.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-mahinder-kotigari-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-manju-katyal-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/manju-katyal.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-manju-katyal-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-rita-bhalla-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/patient-stories/rita-bhalla.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-patient-stories-rita-bhalla-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-questions-to-ask-your-doctor-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/questions-to-ask-your-doctor.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-questions-to-ask-your-doctor-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-resources-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/resources/resources.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-resources-resources-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-search-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/search.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-search-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-brachytherapy-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/treatments/brachytherapy.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-brachytherapy-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-external-beam-radiation-therapy-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/treatments/external-beam-radiation-therapy.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-external-beam-radiation-therapy-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-interventional-oncology-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/treatments/interventional-oncology.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-interventional-oncology-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-proton-therapy-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/treatments/proton-therapy.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-proton-therapy-mdx" */),
  "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-treatments-mdx": () => import("./../../../src/templates/blade-page.tsx?__contentFilePath=/opt/build/repo/src/pages/markdown/en/treatments/treatments.mdx" /* webpackChunkName: "component---src-templates-blade-page-tsx-content-file-path-src-pages-markdown-en-treatments-treatments-mdx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */)
}

