/**
 * Make an object mappable.
 *
 * @format
 * @param {Object} object Object to map over.
 * @param {Function} callback Map function.
 * @returns {Array}
 */

export const mapObject = (
  object: Record<string, unknown>,
  callback: Function
): any[] => {
  return Object.keys(object)
    .sort()
    .map(function (key) {
      return callback(key, object[key]);
    });
};

/**
 * Check if Object is empty, null or undefined.
 *
 * @param {Object | null | undefined} objectName Object to test if it's empty,
 * null or undefined.
 * @return {boolean}
 */
export const isObjectEmptyNullOrUndefined = (
  objectName: Record<string, unknown> | null | undefined
): Boolean => {
  if (objectName === null || typeof objectName === 'undefined') {
    return true;
  }
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};
