/**
 * Translation Context.
 *
 * @format
 */
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';

import type { AllPageData, UITranslations } from '@interfaces';

export type DataProviderProps = {
  children?: ReactNode;
  language?: string;
  legacyPages: AllPageData;
  empowerMePages: AllPageData;
  translations: UITranslations;
  pageType: string;
};

export type SiteDataProps = {
  currentLocale?: string;
  allLegacyPages: AllPageData;
  allEmpowerMePages: AllPageData;
  translations: UITranslations;
  currentPageType: string;
};

export type DataContextProps = {
  siteData: SiteDataProps | null;
  setSiteData: Dispatch<SetStateAction<SiteDataProps>> | null;
};

/**
 * Initial site data context.
 */
export const DataContext = createContext<DataContextProps>({
  siteData: null,
  setSiteData: null,
});

export const useSiteData = () => useContext(DataContext);

/**
 * Data Provider.
 */
export const DataProvider: React.FC<DataProviderProps> = ({
  children,
  language,
  translations,
  legacyPages,
  empowerMePages,
  pageType,
}) => {
  const [siteData, setSiteData] = useState<SiteDataProps>({
    currentLocale: language,
    translations: translations,
    allLegacyPages: legacyPages,
    allEmpowerMePages: empowerMePages,
    currentPageType: pageType,
  });

  /**
   * Update translation context if needed.
   */
  useMemo(() => {
    if (language && translations && legacyPages && empowerMePages && pageType) {
      setSiteData({
        currentLocale: language,
        translations: translations,
        allLegacyPages: legacyPages,
        allEmpowerMePages: empowerMePages,
        currentPageType: pageType,
      });
    }
  }, [
    language,
    translations,
    legacyPages,
    empowerMePages,
    pageType,
    setSiteData,
  ]);

  /**
   * Render.
   */
  return (
    <DataContext.Provider value={{ siteData, setSiteData }}>
      {children}
    </DataContext.Provider>
  );
};
