/** @format */

import React from 'react';
import { Highlight } from 'react-instantsearch';
import { Link } from 'gatsby';

/**
 * Search Result.
 */
const SearchResult: React.FC<{
  hit: any;
}> = ({ hit }) => {
  const { path } = hit;
  if (hit) {
    return (
      <article className="search-result">
        <Link to={path}>
          <div className="hit-title">
            <Highlight attribute="title" hit={hit} />
          </div>
          <div className="hit-description">
            <Highlight attribute="description" hit={hit} />
          </div>
        </Link>
      </article>
    );
  }
  return null;
};
export default SearchResult;
