import React from 'react';
import { useSiteData, DataContextProps } from '@context';
import { Link } from '@base-components';
import { ContactFormButton } from '@components';

/**
 * Footer Navigation.
 */
const FooterNavigation = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations, currentLocale, currentPageType } = siteData;
    const localeLinkPrefix = currentLocale !== 'en' ? `${currentLocale}/` : '';

    /**
     * Render.
     */
    return (
      <div className="footer-navigation">
        <ul>
          {currentPageType === 'empower-me' && (
            <>
              <li>
                <Link url={`/${currentLocale}/resources/faqs`}>
                  {translations?.faqs}
                </Link>
              </li>
              <li>
                <Link url={`/${currentLocale}/resources/glossary`}>
                  {translations?.glossary}
                </Link>
              </li>
            </>
          )}
          {currentPageType === 'legacy' && (
            <>
              {currentLocale !== 'ja' && (
                <li>
                  <Link url={`/${currentLocale}/faqs`}>
                    {translations?.faqs}
                  </Link>
                </li>
              )}
              <li>
                <Link url={`/${currentLocale}/glossary`}>
                  {translations?.glossary}
                </Link>
              </li>
            </>
          )}

          <li>
            <Link
              url={`https://www.varian.com/about-varian/legal-information/copyright-notice-and-trademarks`}
            >
              {translations?.trademarks}
            </Link>
          </li>
          <li className="contact-form-button">
            <ContactFormButton />
          </li>
          <li>
            <Link
              url={`https://www.varian.com/${localeLinkPrefix}about-varian/legal-information/varian-medical-systems-privacy-statement`}
            >
              {translations?.privacy_policy}
            </Link>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};
export default FooterNavigation;
