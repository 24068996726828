/** @format */

import React from 'react';
import { Button } from '@base-components';

/**
 * Japan blurb, replacement for share your story section.
 */
const JaBlurb = (): JSX.Element => {
  /**
   * Handle click away.
   */
  const handleClickAway = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    url: string
  ) => {
    event.preventDefault();
    window.open(url, '_blank');
  };

  /**
   * Render.
   */
  return (
    <div className="japan-container">
      <h2>「放射線治療を受ける方へ」</h2>
      <p>
        放射線治療を受ける方、そのご家族に知っていただきたい情報を、医師の
        監修のもと一つの資料（小冊子形式）にもまとめています。ダウンロード
        またはウェブ上で閲覧ください。
      </p>
      <Button
        buttonType="button"
        onButtonClick={(event) =>
          handleClickAway(
            event,
            'https://varian.widen.net/s/ljc6mskx6b/vao-os-9003_jp_fang-she-xian-zhi-liao-gai-yao-bian_huan-zhe-yong-xiao-ce-zi_20201014'
          )
        }
      >
        放射線治療を受ける方へ（概要編）
      </Button>
      <Button
        buttonType="button"
        onButtonClick={(event) =>
          handleClickAway(
            event,
            'https://varian.widen.net/s/qtwjlpwmr6/vao-os-9006_jp_rt-huan-zhe-yong-xiao-ce-zi_rugan-bianbariandouble-low-line20210408'
          )
        }
      >
        乳がんの放射線治療を受ける方へ
      </Button>
    </div>
  );
};
export default JaBlurb;
