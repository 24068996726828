import React, { CSSProperties } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '@utilities';
import { EmpowerMeLogo } from '@components';
import { usePageData } from '@context';
import type { PageContextProps } from '@context';

/**
 * Homepage Banner.
 */
const HomepageBanner: React.FC<{
  backgroundColor?: string;
  storybookImage?: string;
}> = ({ backgroundColor = 'blue', storybookImage }) => {
  const { pageData }: PageContextProps = usePageData();
  if (!pageData) return null;
  const safeHero = getImageData('featured_banner_image', pageData);
  const safeMobileHero = getImageData('page_image', pageData);

  /**
   * Render.
   */
  if (safeHero && safeMobileHero) {
    return (
      <section
        className={`banner-wrapper background-${backgroundColor} homepage-banner`}
      >
        <div className="img-container">
          {storybookImage && storybookImage !== '' ? (
            <div className="gatsby-image-wrapper">
              <img
                src={storybookImage}
                alt={'empowerME'}
                className="storybook-img"
              />
            </div>
          ) : (
            <>
              <GatsbyImage
                image={safeMobileHero}
                className="mobile-hero-banner"
                alt={'empowerME'}
              />
              <GatsbyImage
                image={safeHero}
                className="hero-banner"
                alt={'empowerME'}
              />
            </>
          )}
        </div>
        <div
          className={`logo-container`}
          style={
            {
              '--logo-background-color': `var(--${backgroundColor})`,
            } as CSSProperties
          }
        >
          <EmpowerMeLogo />
        </div>
      </section>
    );
  }
  return null;
};
export default HomepageBanner;
