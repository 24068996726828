import React, { ReactNode } from 'react';
import TrackVisibility from 'react-on-screen';

/**
 * Blade Section.
 */
const BladeSection: React.FC<{
  backgroundColor?: string;
  children: ReactNode;
  id?: string;
}> = ({ backgroundColor = 'white', children, id }) => {
  return (
    <TrackVisibility
      once
      partialVisibility
      tag="section"
      throttleInterval={200}
    >
      {({ isVisible }) => (
        <div
          className={`blade-wrapper background-${backgroundColor} ${
            /* istanbul ignore next */ isVisible ? '' : 'loading'
          }`}
          id={id}
        >
          <div className="blade-container">{children}</div>
        </div>
      )}
    </TrackVisibility>
  );
};
export default BladeSection;
