import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImageData } from '@utilities';
import {
  useSiteData,
  usePageData,
  DataContextProps,
  PageContextProps,
} from '@context';

/**
 * Page Not Found Banner.
 */
const PageNotFoundBanner = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { pageData }: PageContextProps = usePageData();
  const { siteData }: DataContextProps = useSiteData();
  if (!siteData || !pageData) return null;

  const safeImage = getImageData('simple_banner_image', pageData);
  const { currentLocale } = siteData;

  /**
   * Update Search Box
   */
  const updateSearch = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (
      (event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>)
        .key === 'Enter'
    ) {
      event.preventDefault();
      window.location.assign(
        `/${currentLocale}/search?q=${
          (event.target as HTMLInputElement | HTMLTextAreaElement).value
        }`
      );
    }

    setSearchTerm(
      (event.target as HTMLInputElement | HTMLTextAreaElement).value
    );
  };

  /**
   * Render.
   */
  if (safeImage) {
    return (
      <section
        className={`banner-wrapper background-accent-blue simple-banner page-not-found`}
      >
        <div className="blade-wrapper">
          <div className="blade-container center-vertically">
            <div className="text-container">
              <h2>404</h2>
              <h3>We can’t seem to find the page you’re looking for.</h3>
              <p>Would you like to try searching for something?</p>
              <form className="search-box-form" noValidate role="search">
                <input
                  type="search"
                  className={`search ${
                    searchTerm && searchTerm !== '' ? 'not-empty' : ''
                  }`}
                  value={searchTerm}
                  id="page-not-found-search-box"
                  name="search"
                  onChange={(event) => updateSearch(event)}
                  onKeyDown={(event) => updateSearch(event)}
                  placeholder="Start typing and press enter to see results"
                />
                <i className="fas fa-search" />
              </form>
            </div>
          </div>
        </div>
        <div className="img-container">
          <GatsbyImage image={safeImage} alt="Man searching for something." />
        </div>
      </section>
    );
  }

  return null;
};
export default PageNotFoundBanner;
