/** @format */

import React from 'react';
import { Link } from 'gatsby';
import { mapObject, isObjectEmptyNullOrUndefined } from '@utilities';
import { useSiteData, DataContextProps } from '@context';
import type { NavigationData, MenuData } from '@interfaces';

/**
 * Top Menu Item.
 */
const MenuItem: React.FC<{
  linkTitle: string | boolean;
  path: string;
  menuData: NavigationData;
  pageType: string;
}> = ({ linkTitle, path, menuData, pageType }) => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations } = siteData;
    const menuItems = menuData?.menuItems;
    const hasSubmenu = !isObjectEmptyNullOrUndefined(menuItems);
    const menuItemsLength = menuItems ? Object.entries(menuItems)?.length : 0;

    /**
     * Render.
     */
    return (
      <li className={`top-navigation-item ${hasSubmenu ? '' : 'no-submenu'}`}>
        <Link to={path}>
          {linkTitle}
          {hasSubmenu && <i className="fas fa-angle-down" />}
        </Link>
        {hasSubmenu && menuItems && (
          <div
            className={`drop-down-container${
              menuItemsLength > 10 ? ' wider-menu' : ''
            }`}
          >
            <ul className={menuItemsLength > 10 ? ' menu-2-columns' : ''}>
              {mapObject(menuItems, (key: string) => {
                const { path, id, menu }: MenuData = menuItems[key];
                const safeKey =
                  pageType === 'legacy'
                    ? menu.replace(new RegExp('-', 'g'), '_')
                    : id.replace(new RegExp('-', 'g'), '_');
                if (path && key !== 'all') {
                  return (
                    <li key={key}>
                      <Link to={path}>
                        {translations ? translations[safeKey] : ''}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
      </li>
    );
  }
  return null;
};
export default MenuItem;
