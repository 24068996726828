/** @format */

import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { LanguageSelector } from '@components';
import { useSiteData, useStateContext } from '@context';
import ResponsiveMenuItem from './responsvie-menu-item';
import {
  getSafeTitle,
  buildNavigationData,
  getNavigationOrder,
  isMobileDevice,
} from '@utilities';
import { JaSideNavigation } from '@legacy-components';
import type { DataContextProps, StateContextProps } from '@context';

/**
 * Responsive Menu.
 */
const ResponsiveMenu = (): JSX.Element | null => {
  const { responsiveMenuOpen, setResponsiveMenuOpen }: StateContextProps =
    useStateContext();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { siteData }: DataContextProps = useSiteData();

  /**
   * Close menu when hitting escape.
   */
  const handleKeyPress = (event: KeyboardEvent): void => {
    if (responsiveMenuOpen && event.keyCode === 27) {
      setResponsiveMenuOpen(false);
    }
  };

  /**
   * Update Search Box
   */
  const updateSearch = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (
      (event as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>)
        .key === 'Enter'
    ) {
      event.preventDefault();
      window.location.assign(
        `/${currentLocale}/search?q=${
          (event.target as HTMLInputElement | HTMLTextAreaElement).value
        }`
      );
    }

    setSearchTerm(
      (event.target as HTMLInputElement | HTMLTextAreaElement).value
    );
  };

  /**
   * Setup resize and keypress listeners.
   */
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (responsiveMenuOpen && !isMobileDevice()) {
        setResponsiveMenuOpen(false);
      }
    });
    document.addEventListener('keydown', (event) => handleKeyPress(event));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiveMenuOpen, setResponsiveMenuOpen]);

  if (!siteData) return null;

  const {
    translations,
    currentLocale,
    allLegacyPages,
    allEmpowerMePages,
    currentPageType,
  } = siteData;
  const language = currentLocale ?? 'en';
  const data =
    currentPageType === 'legacy' ? allLegacyPages : allEmpowerMePages;
  const navigationData = buildNavigationData(data, language, currentPageType);
  const navigationOrder = getNavigationOrder(currentPageType);

  /**
   * Render.
   */
  if (navigationData) {
    return (
      <div className="responsive-menu-wrapper">
        <div
          onClick={() => setResponsiveMenuOpen(false)}
          className={
            responsiveMenuOpen
              ? 'show background-overlay'
              : 'background-overlay'
          }
        />
        <nav aria-label={translations.main_navigation}>
          <div
            onClick={() => setResponsiveMenuOpen(!responsiveMenuOpen)}
            className={
              responsiveMenuOpen ? 'show hamburger-button' : 'hamburger-button'
            }
          >
            <div className="bar" />
          </div>
          <div
            className={
              responsiveMenuOpen
                ? 'show responsive-menu-container'
                : 'responsive-menu-container'
            }
          >
            <div className="responsive-inner-container">
              <div className="top-inner-container">
                {/* Search */}
                <div className="responsive-search">
                  <form className="search-box-form" noValidate role="search">
                    <input
                      type="search"
                      className={`search ${
                        searchTerm && searchTerm !== '' ? 'not-empty' : ''
                      }`}
                      value={searchTerm}
                      id="responsive-search-box"
                      name="search"
                      onChange={(event) => updateSearch(event)}
                      onKeyDown={(event) => updateSearch(event)}
                    />
                    <label htmlFor="search">Search</label>
                    <i className="fas fa-search" />
                  </form>
                </div>

                {/* Primary Navigation */}
                {language === 'ja' ? (
                  <>
                    <JaSideNavigation responsiveMenu={true} />
                    <nav className="secondary-navigation">
                      <ul className="secondary-links">
                        <li>
                          <Link
                            to={`/${language}/glossary`}
                            onClick={() => setResponsiveMenuOpen(false)}
                          >
                            {translations.glossary}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </>
                ) : (
                  <div className="primary-navigation">
                    <ul>
                      {navigationData &&
                        navigationOrder.map((navigationKey: string) => {
                          if (navigationKey !== 'no-navigation') {
                            return (
                              <ResponsiveMenuItem
                                // @ts-ignore
                                menuData={navigationData[navigationKey]}
                                title={getSafeTitle(navigationKey, siteData)}
                                pageType={currentPageType}
                                key={navigationKey}
                                path={`/${language}/${navigationKey}/`}
                              />
                            );
                          }
                          return null;
                        })}
                      <li>
                        <Link
                          to={`/${language}/search`}
                          onClick={() => setResponsiveMenuOpen(false)}
                        >
                          {translations.search}
                        </Link>
                      </li>
                    </ul>

                    {/* Auxiliary Navigation */}
                    <nav className="secondary-navigation">
                      <ul className="secondary-links">
                        {currentPageType === 'empower-me' ? (
                          <>
                            <li>
                              <Link
                                to={`/${language}/resources/faqs`}
                                onClick={() => setResponsiveMenuOpen(false)}
                              >
                                {translations.faqs}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/${language}/resources/glossary`}
                                onClick={() => setResponsiveMenuOpen(false)}
                              >
                                {translations.glossary}
                              </Link>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to={`/${language}/faqs`}
                                onClick={() => setResponsiveMenuOpen(false)}
                              >
                                {translations.faqs}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={`/${language}/glossary`}
                                onClick={() => setResponsiveMenuOpen(false)}
                              >
                                {translations.glossary}
                              </Link>
                            </li>
                          </>
                        )}

                        {language !== 'ja' && (
                          <li>
                            <a
                              target="_blank"
                              href="https://www.varian.com/oncology/treatment-locator"
                              rel="noopener noreferrer"
                            >
                              {translations.treatment_locator}
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
              <LanguageSelector selectorType="responsive" />
            </div>
          </div>
        </nav>
      </div>
    );
  }
  return null;
};
export default ResponsiveMenu;
